import axios from "axios";
import CryptoJS from "crypto-js";

const aiHash = "wm3@@nljt3hsdh3sk";

const setSessionKey = (key: string, value: string): void => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  const encryptedData = CryptoJS.AES.encrypt(value, aiHash);
  return localStorage.setItem(key, encryptedData.toString());
};

const getSessionKey = (key: string): any => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData != null) {
    let value = null
    try {
      value = CryptoJS.AES.decrypt(encryptedData, aiHash).toString(CryptoJS.enc.Utf8);
      value = JSON.parse(value);
      return value;
    } catch (error) {
      if (value?.startsWith('"') && value?.endsWith('"'))
        value = value?.slice(1, -1);
      return value;
    }
  } else {
    return null;
  }
};

const removeSessionKey = (key: string): void => {
  return localStorage.removeItem(key);
};

const isLoggedIn = (): boolean => {
  if (
    getSessionKey("authToken") &&
    getSessionKey("authToken").status.toLowerCase() !== "pending" &&
    getSessionKey("authToken").status.toLowerCase() !== "declined"
  ) {
    return true;
    // const expiryTime = parseInt(getSessionKey('expiresIn'));
    // if (expiryTime) {
    //   return expiryTime > new Date().getTime();
    // } else {
    //   return false;
    // }
  }
  return false;
};

// const isSessionValid = (): boolean => {
//   if (getSessionKey('sessionObject')) {
//     return true;
//   } else {
//     return false;
//   }
// };

const clearSession = (): void => {
  localStorage.clear();
};

const logoutSession = (navigate: any): void => {
  const keysToKeep = ["selectedLangCode", "langMaster", "selectedLang"];
  console.log(keysToKeep);
  const keys = Object.keys(localStorage);
  // Iterate over the keys and remove the ones not in keysToKeep
  keys.forEach((key) => {
    if (!keysToKeep.includes(key)) {
      removeSessionKey(key);
    }
  });
  navigate("/login");
};

export {
  setSessionKey,
  getSessionKey,
  removeSessionKey,
  isLoggedIn,
  //   isSessionValid,
  clearSession,
  logoutSession,
};
