import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useDeviceInfo from "src/helper/useDeviceInfo";

export interface OtpData {
  userexists: boolean;
  type: string;
  iconName: string;
  value: string;
  source: string;
  countryCode: string;
}

function OtpPage() {
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [btnValid, setBtnValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const location = useLocation();
  const data: OtpData = location.state;
  const userData = getSessionKey("userData");
  const authData = getSessionKey("authToken");
  const editMail = () => {
    if (location.state.source === "login") {
      navigate("/login", { state: location.state });
    } else if (location.state.source === "personal-detail") {
      navigate("/signup/details-form", { state: location.state });
    } else if (location.state.source === "enter-pin") {
      navigate("/enter-pin", { state: location.state });
    } else if (
      location.state.source === "personal-detail-setting" &&
      data.type === "mobile"
    ) {
      navigate("/accordion-module/update-profile", { state: location.state });
    } else if (
      location.state.source === "personal-detail-setting" &&
      data.type === "email"
    ) {
      navigate("/accordion-module/update-email", { state: location.state });
    }
  };

  const onConfirmClick = () => {
    setLoading(true);
    let otpPayload = {};
    if (data.type === "mobile") {
      otpPayload = {
        contact: `${String(data.countryCode)}${String(data.value)}`,
        otp: otp,
        type: "verification",
      };
    } else {
      otpPayload = {
        email: data.value,
        otp: otp,
        type:
          data.source === "enter-pin"
            ? "forgot-pin"
            : data.userexists
            ? "login"
            : "verification",
      };
    }
    // @ts-ignore
    otpPayload["deviceId"] = deviceId;
    axios
      .post(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/verifyotp",
        otpPayload
      )
      .then(async (res) => {
        if (res.data.success) {
          if (data.type === "mobile") {
            setLoading(false);
            if (data.source === "personal-detail-setting") {
              const payload = {
                id: userData?.id,
                contact: `${String(data.countryCode)}${String(data.value)}`,
              };
              axios
                .put(
                  "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user",
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${
                        getSessionKey("authToken").accesstoken
                      }`,
                    },
                  }
                )
                .then((res) => {
                  // console.log('res', res);
                  if (res.data.success) {
                    userData.contact = `${String(data.countryCode)}${String(
                      data.value
                    )}`;
                    setSessionKey("userData", userData);
                    navigate("/client-approve");
                  } else {
                  }
                })
                .catch((error) => {
                  console.error("Error fetching data:", error.message);
                });
            } else {
              setSessionKey("mobileVerified", "true");
              navigate("/signup/details-form/identity-proof");
            }
          } else {
            if (data.source === "personal-detail-setting") {
              const payload = {
                id: userData?.id,
                email: data.value,
              };
              axios
                .put(
                  "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user",
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${
                        getSessionKey("authToken").accesstoken
                      }`,
                    },
                  }
                )
                .then((res) => {
                  // console.log('res', res);
                  if (res.data.success) {
                    userData.email = data.value;
                    authData.email = data.value;
                    setSessionKey("userData", userData);
                    setSessionKey("authToken", authData);
                    navigate("/client-approve");
                  } else {
                  }
                })
                .catch((error) => {
                  console.error("Error fetching data:", error.message);
                });
            } else if (data.source === "enter-pin") {
              let historyData = {
                source: "forgot-pin",
                email: data.value,
              };
              setLoading(false);
              navigate("/create-pin", { state: historyData });
            } else if (data.userexists) {
              setSessionKey("authToken", res.data.data);
              setSessionKey("userRegStatus", res.data.data.status);
              setSessionKey("userRole", res.data.data.role);
              const postDeviceResponse = await postDeviceInfo(
                res.data.data?.id,
                res.data.data.accesstoken
              );
              // if (!postDeviceResponse?.success){
              //   console.log("Device-Setup-Failed please tray again")
              //   return;
              // }
              if (res.data.data.status.toLowerCase() === "pending") {
                setLoading(false);
                navigate("/signup");
              } else if (
                res.data.data.status.toLowerCase() ===
                  "document approval pending" ||
                res.data.data.status.toLowerCase() === "document reject"
              ) {
                setLoading(false);
                navigate("/impact");
              } else if (res.data.data.status.toLowerCase() === "declined") {
                const headers = {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${
                    getSessionKey("authToken") !== null
                      ? getSessionKey("authToken").accesstoken
                      : ""
                  }`, // Replace with your actual access token
                };
                const userRole = getSessionKey("userRole");
                axios
                  .get(
                    `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user?id=${res.data.data.id}`,
                    { headers }
                  )
                  .then((response) => {
                    setSessionKey("userExistData", response.data.data);
                    if (response.data.success) {
                      setLoading(false);
                      userRole === "legalrepresent"
                        ? navigate("/signup/details-form/company-details")
                        : navigate("/signup/details-form");
                    }
                  })
                  .catch((error) => {
                    console.error("Error fetching data:", error.message);
                    setLoading(false);
                  });
              } else {
                setLoading(false);
                navigate("/impact");
              }
            } else {
              const userRegPayload = {
                email: data.value,
                role: "basic",
                type: "user",
                lang_id: getSessionKey("selectedLang"),
                deviceId: deviceId,
              };
              axios
                .post(
                  "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/register",
                  userRegPayload,
                  {
                    headers: {
                      "skip-otp": false,
                    },
                  }
                )
                .then(async (response) => {
                  // console.log("response-->", response)
                  setSessionKey("authToken", response.data.data);
                  setSessionKey("userRegStatus", res.data.data.status);
                  const postDeviceResponse = await postDeviceInfo(
                      response.data.data?.id,
                      response.data.data.accesstoken
                  );
                  // if (!postDeviceResponse?.success){
                  //   console.log("Device-Setup-Failed please tray again")
                  //   return;
                  // }
                  // postDeviceInfo(
                  //   response?.data?.data?.id,
                  //   response?.data?.data?.accesstoken
                  // );
                  if (response.data.success) {
                    setLoading(false);
                    navigate("/signup");
                  }
                })
                .catch((error) => {
                  console.error("Error fetching data:", error.message);
                  setLoading(false);
                });
            }
          }
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error.message);
        setLoading(false);
        if (error.response.data.error === "Invalid OTP") {
          setShowError(true);
          setBtnValid(true);
        }
        // if(data.type === "mobile") {
        //   navigate("/signup/details-form/identity-proof");
        // }
      });
  };

  useEffect(() => {
    // if(isLoggedIn())
    //   navigate("/impact");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      setBtnValid(false);
      setShowError(false);
    } else {
      setBtnValid(true);
    }
  }, [otp]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    setTimeout(() => {
      clearInterval(timer);
      setResendDisabled(false);
    }, 30 * 1000);
    // return () => {
    //   clearInterval(timer);
    // };
  }, [resendDisabled]);

  const handleResendOTP = () => {
    setLoading(true);
    // Perform OTP resend action here
    // Example: call your API to resend OTP
    // After successful resend, enable the countdown timer and reset it
    let otpPayload = {};
    if (data.type === "mobile") {
      otpPayload = {
        contact: `${String(data.countryCode)}${String(data.value)}`,
        sendby: "sms",
        type: "verification",
      };
    } else {
      otpPayload = {
        email: data.value,
        sendby: "email",
        type:
          data.source === "enter-pin"
            ? "forgot-pin"
            : data.userexists
            ? "login"
            : "verification",
      };
    }
    axios
      .post(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/otp",
        otpPayload
      )
      .then((otpRes) => {
        if (otpRes.data.success) {
          setLoading(false);
          setResendDisabled(true);
          setCountdown(30);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });

    // Simulated API call
    // setTimeout(() => {
    //   setResendDisabled(false);
    // }, 3000); // Assuming 3 seconds for the API call
  };

  const {
    userAgent,
    deviceId,
    timeZone,
    deviceType,
    deviceOrientation,
    connectionType,
    deviceName,
  } = useDeviceInfo();

  const postDeviceInfo = async (
    userId: number | string,
    accessToken: string
  ) => {
    const payload = {
      deviceId: deviceId,
      deviceName: deviceName,
      userAgent: userAgent,
      screenWidthResolution: null,
      screenHeightResolution: null,
      language: null,
      timeZone: timeZone,
      deviceType: deviceType,
      deviceOrientation: deviceOrientation,
      connectionType: connectionType,
      deviceMemory: null,
      cpuCores: null,
      userid: userId,
      token: accessToken,
    };
    if (
      getSessionKey("deviceId") === null ||
      getSessionKey("deviceId") !== deviceId
    ) {
      try {
        const response = await axios.post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/devicedetail",
          payload,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        if (response.data.success) {
          setSessionKey("deviceId", deviceId);
          console.error("success post device details");
          return { error: "", success: true, data: response.data };
        } else {
          return { error: "", success: false };
        }
      } catch (e: any) {
        console.error("Error post device details data:", e?.message);
        return { error: "", success: false };
      }
    }
  };

  const { i18n, t } = useTranslation(["Otp"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="lyt-main typ-otp">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>{t("otp_pgTitle")}</h1>
            <p className={`${styles.subTitle}`}>
              {t("otp_pgSubTitle")} {data?.type}.
            </p>
          </div>
          <div className="bs-form">
            <form>
              <div className="form-body">
                <div className="form-group">
                  <div className={`${showError ? "invalid" : ""} otp-wrap`}>
                    <div className="check-email">
                      <div className="left-wrap">
                        <span className="icon">
                          <i className={`${data?.iconName}`}></i>
                        </span>
                        <span className="icon-text">{data?.value}</span>
                      </div>
                      <div className="right-wrap">
                        <button
                          type="button"
                          className="edit-btn"
                          onClick={editMail}
                        >
                          {t("otp_editBtn")}
                        </button>
                      </div>
                    </div>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    {showError && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("otp_incorrectOtp")}
                      </span>
                    )}
                    <div className="resend-otp">
                      <button
                        type="button"
                        onClick={handleResendOTP}
                        disabled={resendDisabled}
                      >
                        {t("otp_resendBtn")}
                      </button>
                      <span>
                        (in 00:
                        {`${countdown < 10 ? `0${countdown}` : countdown}`})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  disabled={btnValid}
                  onClick={onConfirmClick}
                >
                  {t("otp_confirmBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtpPage;
