import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "src/components/common/button/Button";
import styles from "./style.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { setSessionKey } from "src/helper/sessionServices";
export interface AddClientCardData {
  cardCount: number;
  onConfirm: () => void;
  onDataFromChild: any;
}

function AddClientCard(props: AddClientCardData & { onDataFromChild: any }) {
  const { i18n, t } = useTranslation(["Advisor"]);
  const navigate = useNavigate();
  const lang = getSessionKey("selectedLangCode");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${t("advisor_validationEmail")}`)
      .required(`${t("advisor_validationEmailRequired")}`),
  });
  const relationExists = (obj: any[], val: string) => {
    return obj.some(
      (temp) => temp.investor.email.toLowerCase() === val.toLowerCase()
    );
  };
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        email: values.email,
      };
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/verifyuseremail",
          payload,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            },
          }
        )
        .then((res) => {
          // console.log("res", res, Object.keys(res.data.data).length);
          if (
            res.data.success &&
            Object.keys(res.data.data).length &&
            res.data.data.isActive !== 0
          ) {
            console.log(res.data.data, "Res!!");
            if (
              res.data.data.role !== "individual_investor" &&
              res.data.data.role !== "legalrepresent"
            ) {
              formik.errors.email = "User not an Investor";
              setIsDisabled(false);
              setIsConfirmClicked(false);
              return true;
            }
            //navigate("/signup/details-form/ubo-details");
            if (
              getSessionKey("relationData") !== null &&
              getSessionKey("relationData") !== undefined &&
              getSessionKey("relationData")?.length !== 0
            ) {
              console.log(getSessionKey("relationData"), "RelationData");
              console.log();
              // return true;
              if (relationExists(getSessionKey("relationData"), values.email)) {
                formik.errors.email = "Relation already exists";
                setIsDisabled(false);
                setIsConfirmClicked(false);
                return true;
              }
            }
            setIsConfirmClicked(true);
            setIsDisabled(true);
            props.onConfirm();
            props.onDataFromChild(values.email);
          } else {
            console.log(res.data.message, "Message!!!");
            setIsDisabled(false);
            setIsConfirmClicked(false);
            formik.errors.email = res.data.message;
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
            formik.errors.email = error.message;
          }
        });
    },
  });
  const handleEditClick = () => {
    setIsEditing(false);
    setIsDisabled(false);
    setIsConfirmClicked(false);
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      <div className={`${styles.card} ${isDisabled ? styles.disabled : ""}`}>
        <div className={`${styles.titleWrap}`}>
          <h1 className={`${styles.cardTitle}`}>
            {t("advisor_addClient")} #{props.cardCount}
          </h1>
          <button
            type="button"
            disabled={!isDisabled}
            className="edit-btn"
            onClick={handleEditClick}
          >
            {t("advisor_editBtn")}
          </button>
        </div>
        <div className="bs-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <FormControl variant="standard">
                <TextField
                  id="email"
                  name="email"
                  label="Email ID"
                  placeholder="email ID"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                ></TextField>
              </FormControl>
              {!isConfirmClicked && !isEditing && (
                <Button
                  btnStyle="small"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  {t("advisor_confirmBtn")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddClientCard;
