import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";
import SuccessError from "src/components/success-error/SuccessError";
import Exploration from "src/components/exploration/exploration";

function AdvisorIntro() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/add-advisor");
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="introScreen">
          {getSessionKey("userData")?.status === "freeze" ? (
            <div className="blank-wrap">
              <SuccessError
                title={t("portfoliodetails_frozenAccountTitle")}
                content={t("portfoliodetails_frozenAccountSubTitle")}
                type="account-error"
              />
            </div>
          ) : getSessionKey("userData")?.status ===
              "document approval pending" ||
            getSessionKey("userData")?.status === "document reject" ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("advisor_proceedBtn")}
                navigationLink="/impact"
                logoURL="/assets/icons/kyc-pending.svg"
                description={t("acknowledgement_kycTitle")}
              />
            </div>
          ) : (
            <div className={`${styles.logoContentWrap}`}>
              <i className={`${styles.success}`}></i>
              <div className={`${styles.contentWrap}`}>
                <h2 className={`${styles.title}`}>{t("advisor_introTitle")}</h2>
                <p className={`${styles.description}`}>
                  {t("advisor_introSubTitle")}
                </p>
              </div>
            </div>
          )}
        </div>
      </main>
      {getSessionKey("userData")?.status !== "document approval pending" &&
        getSessionKey("userData")?.status !== "freeze" && (
          <div className={`${styles.footWrap}`}>
            <Button btnStyle="primary" onClick={handleClick}>
              {t("advisor_introTitle")}
            </Button>
          </div>
        )}
    </>
  );
}

export default AdvisorIntro;
