import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import ProfileCard, {
  ProfileData,
} from "src/components/profile-card/ProfileCard";
import { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import {
  getSessionKey,
  removeSessionKey,
  setSessionKey,
} from "src/helper/sessionServices";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function AccountDetails() {
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [desc, setDesc] = useState("");
  const maxLength = 368;
  const navigate = useNavigate();
  const userData = getSessionKey("userData");
  const filterItemsByType = (data: any, type: string) => {
    return data.userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  const companyDetails = filterItemsByType(userData, "company")[0];
  const uboDetails = filterItemsByType(userData, "ubo");
  const lrDetails = filterItemsByType(userData, "lr");
  /* console.log("company-->",companyDetails)
    console.log("ubo-->",uboDetails)
    console.log("lr-->",lrDetails) */
  /* if(userData.role === "legalrepresent"){  
    setSessionKey("userDetails",userData)
    setSessionKey("companyDetails",companyDetails)
    setSessionKey("uboDetails",uboDetails)
    setSessionKey("lrDetails",lrDetails)
  } */
  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (value.length <= maxLength) {
      setInputValue(value);
    }
  };
  const handleClick = () => {
    const payload = {
      id: userData?.id,
      userdetail: {
        about: inputValue,
      },
    };
    axios
      .put("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user", payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        // console.log('res', res);
        if (res.data.success) {
          userData.userdetail.about = inputValue;
          setSessionKey("userData", userData);
          setOpenEditDrawer(false);
        } else {
          setOpenEditDrawer(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const [profileData, setProfileData] = useState<ProfileData>({
    id: userData.userdetail.userid,
    firstName: userData.userdetail.firstname,
    lastName: userData.userdetail.lastname,
    profileImage: userData.userdetail.img,
  });

  const handlePersonalDetails = () => {
    navigate("/accordion-module");
  };
  const handleIdentityProof = () => {
    navigate("/signup/details-form/identity-proof");
    setSessionKey("settingType", "Identity");
  };
  const handleResidenceProof = () => {
    navigate("/signup/details-form/residence-proof");
    setSessionKey("settingType", "Residence");
  };
  const handleCompanyDetails = () => {
    removeSessionKey("companyDetails");
    setSessionKey("settingType", "Company");
    navigate("/signup/details-form/company-details");
  };
  const handleUboDetails = () => {
    removeSessionKey("uboDetails");
    navigate("/signup/details-form/ubo-details");
    setSessionKey("settingType", "UBO");
  };
  const handleLrDetails = () => {
    removeSessionKey("lrDetails");
    navigate("/signup/details-form/legal-representative-details");
    setSessionKey("settingType", "LR");
  };
  useEffect(() => {
    console.log("accountdetails userData", userData, companyDetails);
    if (userData.role !== "legalrepresent") {
      setProfileData({
        id: userData.userdetail.userid,
        firstName: userData.userdetail.firstname,
        lastName: userData.userdetail.lastname,
        profileImage:
          userData.userdetail.img === null
            ? "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"
            : userData.userdetail.img,
      });
    } else {
      setProfileData({
        id: userData.userdetail.userid,
        firstName: companyDetails.companyname,
        lastName: "",
        profileImage:
          userData.userdetail.img === null
            ? "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"
            : userData.userdetail.img,
      });
    }
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section typ-news">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("profilesettings_accountDetails")}
                </h1>
              </div>
              <div className="sec-cont">
                <div className="profile-card">
                  <ProfileCard {...profileData}></ProfileCard>
                </div>
                <div className="bs-confirmation">
                  {userData.role === "advisor" ? (
                    <div className="confirm-card">
                      <div className="head-wrap">
                        <strong className="card-title">
                          {t("profilesettings_about")}
                        </strong>
                        <Button
                          btnStyle="text"
                          onClick={() => setOpenEditDrawer(true)}
                        >
                          <i className={`${styles.arrowRight}`}></i>
                        </Button>
                      </div>
                      <div className="cont-wrap">
                        <ul className="label-value-list">
                          <li className="label-value-item">
                            <label>{t("profilesettings_aboutDesc")}</label>
                            <span className="value">
                              {userData.userdetail.about !== null
                                ? userData.userdetail.about
                                : `${t("profilesettings_aboutSubText")}`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {userData.role !== "legalrepresent" ? (
                    <>
                      <div className="confirm-card">
                        <div className="head-wrap">
                          <strong className="card-title">
                            {t("confirmdetails_personalTitle")}
                          </strong>
                          <Button
                            btnStyle="text"
                            onClick={handlePersonalDetails}
                          >
                            <i className={`${styles.arrowRight}`}></i>
                          </Button>
                        </div>
                        <div className="cont-wrap">
                          <ul className="label-value-list">
                            {userData.email !== null &&
                            userData.email !== "" ? (
                              <li className="label-value-item">
                                <label>{t("companydetails_email")}</label>
                                <span className="value small">{`${userData.email}`}</span>
                              </li>
                            ) : null}
                            {userData.contact !== null ||
                            userData.contact !== "" ? (
                              <li className="label-value-item">
                                <label>{t("companydetails_uboPhone")}</label>
                                <span className="value">{`${userData.contact}`}</span>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </div>

                      <div className="confirm-card">
                        <div className="head-wrap">
                          <strong className="card-title">
                            {t("confirmdetails_identificationTitle")}
                          </strong>
                          <Button btnStyle="text" onClick={handleIdentityProof}>
                            <i className={`${styles.arrowRight}`}></i>
                          </Button>
                        </div>
                        <div className="cont-wrap">
                          <ul className="label-value-list">
                            <li className="label-value-item">
                              <label>
                                {t("companydetails_identityProofTitle")}
                              </label>
                              <span className="value">{`${userData.userdetail.document[0].documentsubtype}`}</span>
                            </li>
                            <li className="label-value-item">
                              <label>{t("companydetails_numberTitle")}</label>
                              <span className="value">{`${userData.userdetail.document[0].documentnumber}`}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {userData.role === "individual_investor" ? (
                    <div className="confirm-card">
                      <div className="head-wrap">
                        <strong className="card-title">
                          {t("profiledetail_residenceProof")}
                        </strong>
                        <Button btnStyle="text" onClick={handleResidenceProof}>
                          <i className={`${styles.arrowRight}`}></i>
                        </Button>
                      </div>
                      <div className="cont-wrap">
                        <ul className="label-value-list">
                          <li className="label-value-item">
                            <label>
                              {t("companydetails_identityProof_address")}
                            </label>
                            <span className="value">
                              {`${userData?.userdetail?.document[1]?.housenumber}, ${userData?.userdetail?.document[1]?.street}, 
                          ${userData?.userdetail?.document[1]?.city}, ${userData?.userdetail?.document[1]?.region}, 
                          ${userData?.userdetail?.document[1]?.provience}, ${userData?.userdetail?.document[1]?.zipcode}`}
                            </span>
                          </li>
                          <li className="label-value-item">
                            <label>{t("companydetails_addressSubTitle")}</label>
                            <span className="value">{`${userData.userdetail.document[1].documentsubtype}`}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {userData.role === "legalrepresent" ? (
                    <>
                      <div className="confirm-card">
                        <div className={`${styles.titleWrap}`}>
                          <strong>{t("accountdetails_companyDetails")}</strong>
                        </div>
                        <div className="head-wrap">
                          <strong className="card-title">
                            {t("accountdetails_detailsTitle")}
                          </strong>
                          <Button
                            btnStyle="text"
                            onClick={handleCompanyDetails}
                          >
                            <i className={`${styles.arrowRight}`}></i>
                          </Button>
                        </div>
                        <div className="cont-wrap">
                          <ul className="label-value-list">
                            <li className="label-value-item">
                              <label>{t("companydetails_taxNumber")}</label>
                              <span className="value">
                                {`${companyDetails.taxnumber}`}
                              </span>
                            </li>
                            <li className="label-value-item">
                              <label>{t("companydetails_email")}</label>
                              <span className="value small">{`${userData.email}`}</span>
                            </li>
                            {companyDetails.contact && (
                              <li className="label-value-item">
                                <label>{t("companydetails_uboPhone")}</label>
                                <span className="value">{`${companyDetails.contact}`}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="confirm-card">
                        <div>
                          <strong>{t("companydetails_uboDetailsTitle")}</strong>
                        </div>
                        {uboDetails?.map((item: any, index: any) => {
                          return (
                            <div>
                              <div className="head-wrap">
                                <strong className="card-title">
                                  UBO {index + 1}
                                </strong>
                                <Button
                                  btnStyle="text"
                                  onClick={handleUboDetails}
                                >
                                  <i className={`${styles.arrowRight}`}></i>
                                </Button>
                              </div>
                              <div className="cont-wrap">
                                <ul className="label-value-list">
                                  <li className="label-value-item">
                                    <label>{t("companydetails_uboName")}</label>
                                    <span className="value">
                                      {`${item.firstname} ${item.lastname}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_uboPhone")}
                                    </label>
                                    <span className="value">{`${item.contact}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_identityProofTitle")}
                                    </label>
                                    <span className="value">{`${item?.document[0]?.documentsubtype}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_numberTitle")}
                                    </label>
                                    <span className="value">{`${item?.document[0]?.documentnumber}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t(
                                        "companydetails_identityProof_address"
                                      )}
                                    </label>
                                    <span className="value">
                                      {`${item?.document[1]?.housenumber}, ${item?.document[1]?.street}, 
                                ${item?.document[1]?.city}, ${item?.document[1]?.region}, 
                                ${item?.document[1]?.provience}, ${item?.document[1]?.zipcode}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_addressSubTitle")}
                                    </label>
                                    <span className="value">{`${item?.document[1]?.documentsubtype}`}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="confirm-card">
                        <div className={`${styles.titleWrap}`}>
                          <strong>{t("companydetails_lrDetailsTitle")}</strong>
                        </div>
                        {lrDetails?.map((item: any, index: any) => {
                          return (
                            <div>
                              <div className="head-wrap">
                                <strong className="card-title">
                                  LR {index + 1}
                                </strong>
                                <Button
                                  btnStyle="text"
                                  onClick={handleLrDetails}
                                >
                                  <i className={`${styles.arrowRight}`}></i>
                                </Button>
                              </div>
                              <div className="cont-wrap">
                                <ul className="label-value-list">
                                  <li className="label-value-item">
                                    <label>{t("companydetails_uboName")}</label>
                                    <span className="value">
                                      {`${item.firstname} ${item.lastname}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_uboPhone")}
                                    </label>
                                    <span className="value">{`${item.contact}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_identityProofTitle")}
                                    </label>
                                    <span className="value">{`${item.document[0]?.documentsubtype}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_numberTitle")}
                                    </label>
                                    <span className="value">{`${item.document[0]?.documentnumber}`}</span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t(
                                        "companydetails_identityProof_address"
                                      )}
                                    </label>
                                    <span className="value">
                                      {`${item?.document[1]?.housenumber}, ${item?.document[1]?.street}, 
                                ${item?.document[1]?.city}, ${item?.document[1]?.region}, 
                                ${item?.document[1]?.provience}, ${item?.document[1]?.zipcode}`}
                                    </span>
                                  </li>
                                  <li className="label-value-item">
                                    <label>
                                      {t("companydetails_addressSubTitle")}
                                    </label>
                                    <span className="value">{`${item?.document[1]?.documentsubtype}`}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <BottomSheet
        primaryBtnText={t("profilesettings_aboutSaveBtn")}
        title={t("profilesettings_about")}
        subText={
          userData.userdetail.about !== null
            ? userData.userdetail.about
            : `${t("profilesettings_aboutSubText")}`
        }
        size="small"
        open={openEditDrawer}
        setOpen={setOpenEditDrawer}
        btnOnClick={handleClick}
        btnDisabled={inputValue.trim().length === 0}
      >
        <div className={`${styles.editDrawer}`}>
          <div className="bs-section typ-news">
            <div className="bs-form">
              <form>
                <div className="form-body">
                  <div className="form-group">
                    <FormControl variant="standard">
                      <TextField
                        id="description"
                        label={t("profilesettings_aboutDesc")}
                        multiline
                        maxRows={6}
                        placeholder={t("profilesettings_aboutSubText")}
                        name="Description"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <p className={`${styles.countText}`}>
                      {inputValue.length} / {maxLength}{" "}
                      {t("profilesettings_aboutCharacters")}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default AccountDetails;
