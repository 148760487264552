import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Header from "src/components/common/header/Header";
import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import ReportsCard, {
  ReportCardData,
} from "src/components/reports-card/ReportsCard";
import styles from "./style.module.scss";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ReportDetails() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [reportsData, setReportsData] = useState<ReportCardData[]>([]);
  const [reportDetail, setReportDetail] = useState<ReportCardData>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reportId = searchParams.get("reportcode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    // Define your header
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setReportsData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    // Make a GET request with the specified headers
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?reportcode=${reportId}&lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setReportDetail(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, [reportId]);

  const summaryCompany =
    reportDetail?.Company?.sectiondata &&
    reportDetail?.Company?.sectiondata.length > 0
      ? reportDetail?.Company?.sectiondata.filter(
          (report) =>
            report.sectionname === "summary" && report.key === "description"
        )
      : [];
  const impactCompany =
    reportDetail?.Company?.sectiondata &&
    reportDetail?.Company?.sectiondata.length > 0
      ? reportDetail?.Company?.sectiondata.filter(
          (report) =>
            report.sectionname === "impact" && report.key === "description"
        )
      : [];
  const sustainGoalCompany =
    reportDetail?.Company?.sectiondata &&
    reportDetail?.Company?.sectiondata.length > 0
      ? reportDetail?.Company?.sectiondata.filter(
          (report) =>
            report.sectionname === "sustainGoal" && report.key === "description"
        )
      : [];
  const summaryReport =
    reportDetail?.sectiondata && reportDetail?.sectiondata.length > 0
      ? reportDetail?.sectiondata.filter(
          (report) =>
            report.sectionname === "summary" && report.key === "description"
        )
      : [];
  const impactReport =
    reportDetail?.sectiondata && reportDetail?.sectiondata.length > 0
      ? reportDetail?.sectiondata.filter(
          (report) =>
            report.sectionname === "impact" && report.key === "description"
        )
      : [];
  const impactReportData =
    reportDetail?.sectiondata && reportDetail?.sectiondata.length > 0
      ? reportDetail?.sectiondata.filter(
          (report) =>
            report.sectionname === "impact" && report.key !== "description"
        )
      : [];
  const summaryReportData =
    reportDetail?.sectiondata && reportDetail?.sectiondata.length > 0
      ? reportDetail?.sectiondata.filter(
          (report) =>
            report.sectionname === "summary" && report.key !== "description"
        )
      : [];
  const summaryCompanyData =
    reportDetail?.Company?.sectiondata &&
    reportDetail?.Company?.sectiondata.length > 0
      ? reportDetail?.Company?.sectiondata.filter(
          (report) =>
            report.sectionname === "summary" && report.key !== "description"
        )
      : [];
  const impactCompanyData =
    reportDetail?.Company?.sectiondata &&
    reportDetail?.Company?.sectiondata.length > 0
      ? reportDetail?.Company?.sectiondata.filter(
          (report) =>
            report.sectionname === "impact" && report.key !== "description"
        )
      : [];
  const sustainGoalCompanyData =
    reportDetail?.Company?.sustainarr &&
    reportDetail?.Company?.sustainarr.length > 0
      ? reportDetail?.Company?.sustainarr.filter((report) => report.SustainGoal)
      : [];
  //console.log("Summary-->"+JSON.stringify(sustainGoalCompanyData))
  console.log("reportDetail", reportDetail);
  const getDownloadObj = {
    filename: reportDetail?.filename,
    fileurl: reportDetail?.fileurl,
    tenure: reportDetail?.tenure,
    CompanyName: reportDetail?.Company?.name,
    PrimaryText: "Download Report",
  };

  console.log("summaryReport", summaryReport[0]?.value, summaryReportData);
  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header
          backLink={true}
          actionItems={["download"]}
          detailData={getDownloadObj}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-report-detail typ-sticky-tabs">
          <div className={`${styles.portfolioHead}`}>
            <div className={`${styles.companyWrap}`}>
              <span className={`${styles.companyLogo}`}>
                <img
                  src={reportDetail?.Company?.companylogo}
                  alt={reportDetail?.Company?.name}
                />
              </span>
              <div className={`${styles.companyIntro}`}>
                <strong className={`${styles.companyName}`}>
                  {reportDetail?.Company?.name}
                </strong>
                <span className={`${styles.tag}`}>
                  {reportDetail?.Company?.country}
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles.reportBody}`}>
            <div className="bs-tabs typ-list count-2">
              <Tabs value={value} onChange={handleChange}>
                <Tab label={t("companydetails_reportsTitle")} />
                <Tab label={t("companydetails_overviewTitle")} />
              </Tabs>
              <div className={`${styles.panelWrap}`}>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={value !== 0}
                >
                  <div className={`${styles.tabDataList}`}>
                    {summaryReport[0]?.value !== undefined ||
                    summaryReportData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewSummary")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          {/* <p className={`${styles.cardDesc}`}>
                            {summaryReport[0].value}
                          </p> */}
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: summaryReport[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {summaryReportData.map((report, index) => (
                                <div
                                  className={`${styles.tableItem}`}
                                  key={index}
                                >
                                  <span className={`${styles.label}`}>
                                    {report.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {report.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {impactReport[0]?.value !== undefined ||
                    impactReportData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewImpact")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          {/* <p className={`${styles.cardDesc}`}>
                            Banyan Nation’s revenues increased 36.5%YoY and
                            +9.8%QoQ and volumes of premium granules sold this
                            quarter grew Show More 
                            {impactReport[0].value}
                          </p> */}
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: impactReport[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {/* <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Collected
                                </span>
                                <span className={`${styles.value}`}>
                                  120 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Recycled
                                </span>
                                <span className={`${styles.value}`}>
                                  130 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Volume Sold
                                </span>
                                <span className={`${styles.value}`}>
                                  1054 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Premium Sales (Volume)
                                </span>
                                <span className={`${styles.value}`}>
                                  929 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Granules Produced
                                </span>
                                <span className={`${styles.value}`}>
                                  877 Tonnes
                                </span>
                              </div> */}
                              {impactReportData.map((report, index) => (
                                <div
                                  className={`${styles.tableItem}`}
                                  key={index}
                                >
                                  <span className={`${styles.label}`}>
                                    {report.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {report.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {reportsData && reportsData.length > 1 ? (
                    <div className="bs-section typ-more-reports">
                      <div className="sec-head">
                        <h2 className="cm-sec-title typ-sm">
                          {t("companydetails_moreReportsTitle")}
                        </h2>
                      </div>
                      <div className="sec-cont">
                        <div className="bs-swiper typ-reports typ-detail">
                          <SwiperComponent
                            swiperConfig={{
                              slidesPerView: "auto",
                              spaceBetween: 12,
                              observer: true,
                              cssMode: true,
                              breakpoints: {
                                768: {
                                  spaceBetween: 20,
                                  cssMode: false,
                                },
                                1280: {
                                  cssMode: false,
                                  spaceBetween: 24,
                                },
                              },
                            }}
                          >
                            {reportsData
                              ?.filter((item) => item.reportcode != reportId)
                              .map((item, index) => {
                                return (
                                  <div key={index} className="slide-item">
                                    <ReportsCard {...item}></ReportsCard>
                                  </div>
                                );
                              })}
                          </SwiperComponent>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={value !== 1}
                >
                  <div className={`${styles.tabDataList}`}>
                    {sustainGoalCompany[0]?.value !== undefined ||
                    sustainGoalCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewSustainability")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          {/* <p className={`${styles.cardDesc}`}>
                            Banyan Nation over the years has strived to achieve
                            UNDP’s sustainability goals. As they continue the
                            journey towards sustainability, here are some goals
                            they have accomplished and constantly work towards.
                          </p> */}
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: sustainGoalCompany[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.subWrap}`}>
                            <ul className={`${styles.subList}`}>
                              {/* <li className={`${styles.subItem}`}>
                                <i className={`${styles.growth}`}></i>
                                <span className={`${styles.text}`}>
                                  Decent Work and Economic Growth
                                </span>
                              </li>
                              <li className={`${styles.subItem}`}>
                                <i className={`${styles.industry}`}></i>
                                <span className={`${styles.text}`}>
                                  Industry Innovation and Infrastructure
                                </span>
                              </li>
                              <li className={`${styles.subItem}`}>
                                <i className={`${styles.climate}`}></i>
                                <span className={`${styles.text}`}>
                                  Climate Action
                                </span>
                              </li> */}
                              {sustainGoalCompanyData.map((report, index) => (
                                <li className={`${styles.subItem}`} key={index}>
                                  <i
                                    className={`${report.SustainGoal.classname}`}
                                  ></i>
                                  <span className={`${styles.text}`}>
                                    {report.SustainGoal.value}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {summaryCompany[0]?.value !== undefined ||
                    summaryCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewSummary")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          {/* <p className={`${styles.cardDesc}`}>
                            Banyan Nation is one of India's first vertically
                            integrated plastic recycling companies, helping global
                            brands use more recycled plastic instead of virgin
                            plastic. The company is a Show More
                          </p> */}
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: summaryCompany[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {summaryCompanyData.map((report, index) => (
                                <div
                                  className={`${styles.tableItem}`}
                                  key={index}
                                >
                                  <span className={`${styles.label}`}>
                                    {report.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {report.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {impactCompany[0]?.value !== undefined ||
                    impactCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("companydetails_overviewImpact")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          {/* <p className={`${styles.cardDesc}`}>
                            Banyan Nation’s revenues increased 36.5%YoY and
                            +9.8%QoQ and volumes of premium granules sold this
                            quarter grew Show More
                          </p> */}
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: impactCompany[0]?.value || "",
                            }}
                          ></div>

                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {/* <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Collected
                                </span>
                                <span className={`${styles.value}`}>
                                  120 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Recycled
                                </span>
                                <span className={`${styles.value}`}>
                                  130 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Volume Sold
                                </span>
                                <span className={`${styles.value}`}>
                                  1054 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Premium Sales (Volume)
                                </span>
                                <span className={`${styles.value}`}>
                                  929 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Granules Produced
                                </span>
                                <span className={`${styles.value}`}>
                                  877 Tonnes
                                </span>
                              </div> */}
                              {impactCompanyData.map((report, index) => (
                                <div
                                  className={`${styles.tableItem}`}
                                  key={index}
                                >
                                  <span className={`${styles.label}`}>
                                    {report.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {report.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReportDetails;
