import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface DeviceCardData {
  deviceName: string;
  location: string;
  status: string;
  isActive?: boolean;
}

function DeviceCard(props: any) {
  const { i18n, t } = useTranslation(["ActiveDevice"]);
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<any | null>(null);
  const handleTrashIconClick = () => {
    setSelectedDevice(props);
    setOpenDeleteDrawer(true);
  };
  const handleDeleteDevice = () => {
    console.log("Deleting Device:", selectedDevice);
    setOpenDeleteDrawer(false);
    props.removeSelectedDevice(selectedDevice?.id);
  };

  const dateTimeFormatter = (t: string) => {
    const timestamp = t;
    const date = new Date(timestamp);

    // Format components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Combine into desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };
  return (
    <>
      <div className={`${styles.cardWrap}`}>
        <div className={`${styles.card}`}>
          <div className={`${styles.deviceWrap}`}>
            <div className={`${styles.deviceDetails}`}>
              <div className={`${styles.infoWrap}`}>
                <div className={`${styles.deviceInfo}`}>
                  <i className={`${styles.mobile}`}></i>
                  <p className={`${styles.deviceTitle}`}>{props.deviceType}</p>
                </div>
                <div className={`${styles.deviceInfo}`}>
                  <i className={`${styles.location}`}></i>
                  <p className={`${styles.deviceTitle}`}>{props.timeZone}</p>
                </div>
              </div>
              <p
                className={`${styles.deviceStatus} ${
                  props.currentDeviceId === props.deviceId
                    ? `${styles.active}`
                    : ""
                }`}
              >
                {props.currentDeviceId === props.deviceId
                  ? "This Device"
                  : `Last active on ${dateTimeFormatter(props.updatedAt)}`}
              </p>
            </div>
            {props.currentDeviceId !== props.deviceId && (
              <button type="button" onClick={() => handleTrashIconClick()}>
                <i className={`${styles.trash}`}></i>
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedDevice && openDeleteDrawer && (
        <BottomSheet
          title={t("activedevices_drawerRemoveTitle")}
          subText={t("activedevices_drawerRemoveSubTitle")}
          primaryBtnText={t("activedevices_drawerRemoveTitle")}
          size="small"
          open={openDeleteDrawer}
          setOpen={setOpenDeleteDrawer}
          btnOnClick={handleDeleteDevice}
        >
          <div className={`${styles.drawer}`}>
            <div className={`${styles.cardWrap}`}>
              <div className={`${styles.card}`}>
                <div className={`${styles.deviceWrap}`}>
                  <div className={`${styles.deviceDetails}`}>
                    <div className={`${styles.infoWrap}`}>
                      <div className={`${styles.deviceInfo}`}>
                        <i className={`${styles.mobile}`}></i>
                        <p className={`${styles.deviceTitle}`}>
                          {props.deviceType}
                        </p>
                      </div>
                      <div className={`${styles.deviceInfo}`}>
                        <i className={`${styles.location}`}></i>
                        <p className={`${styles.deviceTitle}`}>
                          {props.timeZone}
                        </p>
                      </div>
                    </div>
                    <p
                      className={`${styles.deviceStatus} ${
                        props.currentDeviceId === props.deviceId
                          ? `${styles.active}`
                          : ""
                      }`}
                    >
                      {props.currentDeviceId === props.deviceId
                        ? "This Device"
                        : `Last active on ${dateTimeFormatter(
                            props.updatedAt
                          )}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
      )}
    </>
  );
}

export default DeviceCard;
