import React, { useState, useEffect } from "react";
import Header from "src/components/common/header/Header";
import { Tab, Tabs } from "@mui/material";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import CheckBox from "src/components/common/checkbox/CheckBox";
import ReportsCard, {
  ReportCardData,
} from "src/components/reports-card/ReportsCard";
import styles from "./style.module.scss";
import BuyerSellerCard, {
  BuyerSellerCardData,
} from "../../components/buyer-seller-card/BuyerSellerCard";
import RequestCard from "src/components/request-card/RequestCard";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoader } from "src/context/LoaderContext";
import axios from "axios";
import Loader from "src/components/common/loader/Loader";

function PortfolioDetail() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["PortfolioDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const [mainTabValue, setMainTabValue] = useState(0);
  const [bottomSheetTabValue, setBottomSheetTabValue] = useState(0);
  const [openSortDrawer, setOpenSortDrawer] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const portfolioId = searchParams.get("id");
  const [portfolioData, setPortfolioData] = useState<any>({});
  const { loading, setLoading } = useLoader();

  const handleChangeMainTabs = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setMainTabValue(newValue);
  };

  const handleChangeBottomSheetTabs = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setBottomSheetTabValue(newValue);
  };
  const [reportsData, setReportsData] = useState<ReportCardData[]>([
    /* {
          id: "001",
          filename: "Banyan Nation",
          tenure: "2022-23",
          reporttype: "Annual Report",
          addhome: true
        },
        {
          id: "002",
          filename: "Vilcart Tech.",
          tenure: "2022-23",
          reporttype: "Annual Report",
          addhome: true
        },
        {
          id: "001",
          filename: "One Puhunan",
          tenure: "2022-23",
          reporttype: "Annual Report",
          addhome: true
        },
        {
          id: "001",
          filename: "Credit Access India",
          tenure: "2022-23",
          reporttype: "Annual Report",
          addhome: true
        }, */
  ]);
  const requestsData: BuyerSellerCardData[] = [
    {
      cardId: "01",
      cardType: "seller",
      date: "12th Oct’23",
      count: 2,
      investmentCount: 450,
      bidPrice: "€2500",
      singlePrice: "€5",
      chatFeature: true,
    },
    {
      cardId: "02",
      cardType: "seller",
      date: "12th Oct’23",
      count: 1,
      investmentCount: 450,
      bidPrice: "€3600",
      singlePrice: "€5",
      chatFeature: true,
    },
  ];
  const sortTabItems: { [key: string]: any[] } = {
    en: [
      {
        id: "tab01",
        title: "Title",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "checkbox",
            itemId: "request1",
            label: "Request 1",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "checkbox",
            itemId: "request2",
            label: "Request 2",
            count: 0,
            isChecked: false,
          },
        ],
      },
      {
        id: "tab02",
        title: "Date",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "radio",
            itemId: "last1month",
            label: "Last 1 Month",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "last3months",
            label: "Last 3 Months",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "all",
            label: "All",
            count: 0,
            isChecked: false,
          },
        ],
      },
    ],
    it: [
      {
        id: "tab01",
        title: "Title",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "checkbox",
            itemId: "request1",
            label: "Request 1",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "checkbox",
            itemId: "request2",
            label: "Request 2",
            count: 0,
            isChecked: false,
          },
        ],
      },
      {
        id: "tab02",
        title: "Data",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "radio",
            itemId: "last1month",
            label: "Ultimo mese",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "last3months",
            label: "Ultimi 3 mesi",
            count: 0,
            isChecked: false,
          },
          {
            itemType: "radio",
            itemId: "all",
            label: "Tutte",
            count: 0,
            isChecked: false,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/portfolio?id=${portfolioId}&lang_id=${
          getSessionKey("userData")?.lang_id
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        console.log(response?.data?.data[0]);
        setPortfolioData(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId]);

  useEffect(() => {
    if (portfolioData.length !== 0) {
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?lang_id=${
            getSessionKey("userData")?.lang_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          let companyReport = response.data?.data.filter(
            (obj: any) => obj.companyid === portfolioData.companyid
          );
          // console.log(companyReport, "Company Repo!!!");
          setReportsData(companyReport);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  }, [portfolioData]);

  // useEffect(() => {
  //   if(Object.keys(portfolioData).length > 0 ) {
  //     const summaryCompany =
  //       portfolioData?.Company?.sectiondata && portfolioData?.Company?.sectiondata.length > 0
  //         ? portfolioData?.Company?.sectiondata.filter(
  //             (comapny: any) =>
  //               comapny.sectionname === "summary" && comapny.key === "description"
  //           )
  //         : [];
  //     const impactCompany =
  //       portfolioData?.Company?.sectiondata && portfolioData?.Company?.sectiondata.length > 0
  //         ? portfolioData?.Company?.sectiondata.filter(
  //             (comapny: any) =>
  //               comapny.sectionname === "impact" && comapny.key === "description"
  //           )
  //         : [];
  //     const sustainGoalCompany =
  //       portfolioData?.Company?.sectiondata && portfolioData?.Company?.sectiondata.length > 0
  //         ? portfolioData?.Company?.sectiondata.filter(
  //             (comapny: any) =>
  //               comapny.sectionname === "sustainGoal" &&
  //               comapny.key === "description"
  //           )
  //         : [];
  //     const summaryCompanyData =
  //       portfolioData?.Company?.sectiondata && portfolioData?.Company?.sectiondata.length > 0
  //         ? portfolioData?.Company?.sectiondata.filter(
  //             (comapny: any) =>
  //               comapny.sectionname === "summary" && comapny.key !== "description"
  //           )
  //         : [];
  //     const impactCompanyData =
  //       portfolioData?.Company?.sectiondata && portfolioData?.Company?.sectiondata.length > 0
  //         ? portfolioData?.Company?.sectiondata.filter(
  //             (comapny: any) =>
  //               comapny.sectionname === "impact" && comapny.key !== "description"
  //           )
  //         : [];
  //     const sustainGoalCompanyData =
  //       portfolioData?.Company?.sustainarr && portfolioData?.Company?.sustainarr.length > 0
  //         ? portfolioData?.Company?.sustainarr.filter((comapny: any) => comapny.SustainGoal)
  //         : [];
  //   }
  // }, [portfolioData])

  const summaryCompany =
    portfolioData?.Company?.sectiondata &&
    portfolioData?.Company?.sectiondata.length > 0
      ? portfolioData?.Company?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "summary" && comapny.key === "description"
        )
      : [];
  const impactCompany =
    portfolioData?.Company?.sectiondata &&
    portfolioData?.Company?.sectiondata.length > 0
      ? portfolioData?.Company?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "impact" && comapny.key === "description"
        )
      : [];
  const sustainGoalCompany =
    portfolioData?.Company?.sectiondata &&
    portfolioData?.Company?.sectiondata.length > 0
      ? portfolioData?.Company?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "sustainGoal" &&
            comapny.key === "description"
        )
      : [];
  const summaryCompanyData =
    portfolioData?.Company?.sectiondata &&
    portfolioData?.Company?.sectiondata.length > 0
      ? portfolioData?.Company?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "summary" && comapny.key !== "description"
        )
      : [];
  const impactCompanyData =
    portfolioData?.Company?.sectiondata &&
    portfolioData?.Company?.sectiondata.length > 0
      ? portfolioData?.Company?.sectiondata.filter(
          (comapny: any) =>
            comapny.sectionname === "impact" && comapny.key !== "description"
        )
      : [];
  const sustainGoalCompanyData =
    portfolioData?.Company?.sustainarr &&
    portfolioData?.Company?.sustainarr.length > 0
      ? portfolioData?.Company?.sustainarr.filter(
          (comapny: any) => comapny.SustainGoal
        )
      : [];

  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header backLink={true} pageTitle=""></Header>
      </header>
      <main>
        <div className="lyt-main typ-sticky-tabs">
          <div className={`${styles.portfolioHead}`}>
            <div className={`${styles.companyWrap}`}>
              <span className={`${styles.companyLogo}`}>
                <img src={portfolioData?.Company?.companylogo} alt="" />
              </span>
              <div className={`${styles.companyIntro}`}>
                <strong className={`${styles.companyName}`}>
                  {portfolioData?.Company?.name}
                </strong>
                <span className={`${styles.tag}`}>
                  {portfolioData?.Company?.country}
                </span>
              </div>
            </div>
            <div className={`${styles.labelValueWrap}`}>
              <span className={`${styles.label}`}>
                {t("portfoliodetails_myDrs")}
              </span>
              <span className={`${styles.value}`}>
                {portfolioData?.count} {t("portfoliodetails_drs")}
              </span>
            </div>
          </div>
          <div className={`${styles.companyBody}`}>
            <div className={`${styles.imgWrap}`}>
              <img src={portfolioData?.Company?.companylogo} alt="" />
            </div>
            <div className="bs-tabs typ-list">
              <Tabs value={mainTabValue} onChange={handleChangeMainTabs}>
                <Tab label={t("portfoliodetails_overviewTabTitle")} />
                <Tab label={t("portfoliodetails_reportsTabTitle")} />
                <Tab
                  label={
                    <React.Fragment>
                      {t("portfoliodetails_requestsTabTitle")}
                      <span className="count">
                        {portfolioData?.Company?.ActiveRequests.length}
                      </span>
                    </React.Fragment>
                  }
                />
              </Tabs>
              <div className={`${styles.panelWrap}`}>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={mainTabValue !== 0}
                >
                  <div className={`${styles.overviewList}`}>
                    {sustainGoalCompany[0]?.value !== undefined ||
                    sustainGoalCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("portfoliodetails_overviewSustainability")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: sustainGoalCompany[0]?.value || "",
                            }}
                          ></div>
                          <div className={`${styles.subWrap}`}>
                            <ul className={`${styles.subList}`}>
                              {sustainGoalCompanyData.map((company: any) => (
                                <li className={`${styles.subItem}`}>
                                  <i
                                    className={`${company.SustainGoal.classname}`}
                                  ></i>
                                  <span className={`${styles.text}`}>
                                    {company.SustainGoal.value}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {summaryCompany[0]?.value !== undefined ||
                    summaryCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("portfoliodetails_overviewSummary")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: summaryCompany[0]?.value || "",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                    {impactCompany[0]?.value !== undefined ||
                    impactCompanyData.length > 0 ? (
                      <div className={`${styles.cardItem}`}>
                        <div className={`${styles.cardHead}`}>
                          <h3 className={`${styles.cardTitle}`}>
                            {t("portfoliodetails_overviewImpact")}
                          </h3>
                        </div>
                        <div className={`${styles.cardCont}`}>
                          <div
                            className="cont-body"
                            dangerouslySetInnerHTML={{
                              __html: impactCompany[0]?.value || "",
                            }}
                          ></div>
                          {/* <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Collected
                                </span>
                                <span className={`${styles.value}`}>
                                  120 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Plastic Recycled
                                </span>
                                <span className={`${styles.value}`}>
                                  130 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Volume Sold
                                </span>
                                <span className={`${styles.value}`}>
                                  1054 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Premium Sales (Volume){" "}
                                </span>
                                <span className={`${styles.value}`}>
                                  929 Tonnes
                                </span>
                              </div>
                              <div className={`${styles.tableItem}`}>
                                <span className={`${styles.label}`}>
                                  Granules Produced
                                </span>
                                <span className={`${styles.value}`}>
                                  877 Tonnes
                                </span>
                              </div>
                            </div>
                          </div> */}
                          <div className={`${styles.tableWrap}`}>
                            <div className={`${styles.tableBody}`}>
                              {impactCompanyData.map((company: any) => (
                                <div className={`${styles.tableItem}`}>
                                  <span className={`${styles.label}`}>
                                    {company.key}
                                  </span>
                                  <span className={`${styles.value}`}>
                                    {company.value}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={mainTabValue !== 1}
                >
                  <div className={`${styles.reportsList}`}>
                    {reportsData.map((item, index) => {
                      return (
                        <div key={index} className={`${styles.item}`}>
                          <ReportsCard {...item} bigCard={true}></ReportsCard>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`${styles.tabPanel}`}
                  role="tabpanel"
                  hidden={mainTabValue !== 2}
                >
                  <div className={`${styles.requestsWrap}`}>
                    <div className={`${styles.headWrap}`}>
                      <h3 className={`${styles.listTitle}`}>
                        {t("portfoliodetails_activeRequestsTitle")}
                      </h3>
                      {/* <button
                        className={`${styles.sortBtn}`}
                        onClick={() => setOpenSortDrawer(true)}
                      >
                        <i></i>
                        {t("portfoliodetails_sortBtn")}
                      </button> */}
                    </div>
                    <div className={`${styles.requestsList}`}>
                      {portfolioData?.Company?.ActiveRequests &&
                        portfolioData?.Company?.ActiveRequests.sort(
                          (a: any, b: any) => {
                            const dateA = a.createdAt
                              ? new Date(a.createdAt).getTime()
                              : 0;
                            const dateB = b.createdAt
                              ? new Date(b.createdAt).getTime()
                              : 0;
                            return dateB - dateA;
                          }
                        ).map((item: any, index: number) => {
                          return (
                            <div
                              className={`${styles.requestsItem}`}
                              key={index}
                            >
                              <RequestCard
                                {...item}
                                parentRef="companyDetails"
                                index={index + 1}
                                companyName={portfolioData?.Company?.name}
                              ></RequestCard>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("portfoliodetails_sortBtn")}
        primaryBtnText={t("portfoliodetails_applyBtn")}
        secondaryBtnText={t("portfoliodetails_clearAllBtn")}
        size="large"
        open={openSortDrawer}
        setOpen={setOpenSortDrawer}
      >
        <div className="bs-filter">
          <div className="left-wrap">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={bottomSheetTabValue}
              onChange={handleChangeBottomSheetTabs}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {sortTabItems[i18n.language].map((item: any, index: number) => {
                return <Tab key={index} label={item.title} />;
              })}
            </Tabs>
          </div>
          <div className="right-wrap">
            {sortTabItems[i18n.language].map((item: any, index: number) => {
              return (
                <div
                  className="tabpanel"
                  role="tabpanel"
                  hidden={bottomSheetTabValue !== index}
                  key={index}
                >
                  <div className="panel-list">
                    {item.tabPanelItems.map((item: any, index: number) => {
                      return (
                        <div className="filter-item" key={index}>
                          {item.itemType === "radio" ? (
                            <>
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id={item.itemId}
                                  name={item.itemType}
                                />
                                <label htmlFor={item.itemId}>
                                  {item.label}
                                </label>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {item.itemType === "checkbox" ? (
                            <>
                              <CheckBox
                                id={item.itemId}
                                label={item.label}
                                name={item.itemId}
                              ></CheckBox>
                            </>
                          ) : (
                            ""
                          )}
                          {item.count !== 0 ? (
                            <span className="count">{item.count}</span>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                    `
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default PortfolioDetail;
