import { useEffect, useState } from "react";
import Button from "../common/button/Button";
import styles from "./style.module.scss";
import BottomSheet from "../bottom-sheet/BottomSheet";
import DocumentUpload from "../common/document-upload/DocumentUpload";
import RadioGroupField, {
  RadioField,
} from "src/components/common/radio-group/RadioGroupField";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IdentityProof, {
  DocumentDataType,
  IdentityProofObj,
} from "src/pages/signup/common/identity-proof/IdentityProof";
import { useFormik } from "formik";
import * as yup from "yup";
import ResidenceProof, {
  ResidencyProofDataType,
} from "src/pages/signup/common/residence-proof/ResidenceProof";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface UploadCardProps {
  id: number | string | null;
  cardId: string;
  uploadType: "Document" | "Address" | "Identity Proof";
  uploadText: string;
  cardTitle?: string;
  helpText?: string;
  popupTitle: string;
  popupDesc: string;
  uploadStatus?: boolean;
  documentData?: DocumentDataType | null;
  docFileObj?: any | null;
  errorMsg?: string;
  index?: any;
  fieldKey?: string;
  docSecDataObj?: any | null;
  formikObj?: any;
  financialStatement1?: UploadCardProps;
  fieldTitle?: string;
  onFileUpload?: (
    file: any,
    fileData: DocumentDataType | null | undefined,
    index: number,
    data?: IdentityProofObj | ResidencyProofDataType | null,
    fieldKey?: string,
    file1?: any,
    fileData1?: DocumentDataType | null
  ) => void;
}

function UploadCard(
  props: UploadCardProps & { uploaded: boolean | undefined }
) {
  const { i18n, t } = useTranslation(["IdentityProof"]);
  const [docType, setDocType] = useState("passport");
  const [editState, setEditState] = useState(false);
  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);
  const [previewFileURL, setPreviewFileURL] = useState<string | undefined>("");
  const [previewFileURL1, setPreviewFileURL1] = useState<string | undefined>("");
  // validation logic for identity proof form
  // const formik = useFormik({
  //   initialValues: {
  //     passport: "",
  //     expiryDatePassport: null,
  //     // expiryDateIDProof: "",
  //     idNum: "",
  //   },
  //   validationSchema: validationSchema,
  //   // onSubmit: (values) => {
  //   //   navigate("/signup/details-formlegal-representative-details");
  //   // },
  // });

  const docTypeRadio: RadioField[] = [
    {
      value: `passport`,
      label: `${t("signupintro_drawerPassport")}`,
    },
    {
      value: `identity`,
      label: `${t("signupintro_drawerIdentityCard")}`,
    },
  ];

  const identityDocChange = (e: any) => {
    if (e.target.value === "passport") {
      setDocType("passport");
    }
    if (e.target.value === "identity card") {
      setDocType("identity card");
    }
  };

  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [fileData, setFileData] = useState<DocumentDataType | null | undefined>(
    {
      fileurl: "",
      filename: "",
      filesize: "",
      filetype: "",
    }
  );
  const [fileData1, setFileData1] = useState<
    DocumentDataType | null | undefined
  >({
    fileurl: "",
    filename: "",
    filesize: "",
    filetype: "",
  });
  const [confirmBtnState, setConfirmBtnState] = useState(true);
  const handleFileUpload = (
    file: any,
    uploadedFileData: DocumentDataType | null,
    index?: number
  ) => {
    // console.log('uploadedFileData', uploadedFileData, file, fileData, index);
    if (props.fieldKey === "financialStatements") {
      if (index === 0) {
        setPreviewFileURL(
          uploadedFileData === null ? "" : uploadedFileData?.fileurl
        );
        setFileData(uploadedFileData);
        setFile(file);
      } else if (index === 1) {
        setPreviewFileURL1(
          uploadedFileData === null ? "" : uploadedFileData?.fileurl
        );
        setFileData1(uploadedFileData);
        setFile1(file);
      }
    } else {
      setPreviewFileURL(
        uploadedFileData === null ? "" : uploadedFileData?.fileurl
      );
      setFileData(uploadedFileData);
      setFile(file);
      if (uploadedFileData === null) setConfirmBtnState(true);
      else setConfirmBtnState(false);
    }
  };

  useEffect(() => {
    if (props.fieldKey === "financialStatements") {
      if (fileData?.fileurl === "" || fileData1?.fileurl === "")
        setConfirmBtnState(true);
      else setConfirmBtnState(false);
    }
  }, [fileData, fileData1]);

  const handleDocumentUploadConfirm = () => {
    if (props.onFileUpload) {
      props.onFileUpload(
        file,
        fileData,
        props.index,
        null,
        props.fieldKey,
        file1,
        fileData1
      );
      setOpenUploadDrawer(false);
    }
  };

  const handleDataFromChildIdentityProof = (
    data: IdentityProofObj,
    file: any,
    fileData: any
  ) => {
    if (props.onFileUpload) {
      props.onFileUpload(file, fileData, props.index, data, props.fieldKey);
    }
    setOpenUploadDrawer(false);
  };

  const handleDataFromChildResidencyProof = (
    data: ResidencyProofDataType,
    file: any,
    fileData: any
  ) => {
    if (props.onFileUpload) {
      props.onFileUpload(file, fileData, props.index, data, props.fieldKey);
    }
    setOpenUploadDrawer(false);
  };

  useEffect(() => {
    if (editState) {
      // console.log('props.fieldKey', props.fieldKey)
      setPreviewFileURL(
        props?.documentData !== null ? props?.documentData?.fileurl : ""
      );
      setFileData(props?.documentData);
      setFile(props?.docFileObj);
      if (props.fieldKey === "financialStatements") {
        setPreviewFileURL1(
          props?.financialStatement1?.documentData !== null
            ? props?.financialStatement1?.documentData?.fileurl
            : ""
        );
        setFileData1(props?.financialStatement1?.documentData);
        setFile1(props?.financialStatement1?.docFileObj);
      }
    }
  }, [editState]);

  // Utility function to get the current financial year
  const getCurrentFinancialYear = (): string => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Financial year starts in April (month index 3)
    if (currentMonth >= 3) {
      return `${currentYear - 1} - ${currentYear}`;
    } else {
      return `${currentYear - 2} - ${currentYear - 1}`;
    }
  };

  // Utility function to get the Last financial year
  const getLastFinancialYear = (): string => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Financial year starts in April (month index 3)
    if (currentMonth >= 3) {
      return `${currentYear - 2} - ${currentYear - 1}`;
    } else {
      return `${currentYear - 3} - ${currentYear - 2}`;
    }
  };

  return (
    <>
      <div className="upload-group">
        {props.cardTitle ? (
          <span className="upload-card-title">
            {props.cardTitle}{" "}
            <Tooltip title={props.helpText} enterTouchDelay={0} arrow={true}>
              <i></i>
            </Tooltip>
          </span>
        ) : (
          ""
        )}
        <div className="upload-card">
          {props.uploaded ? (
            <div
              className={`uploaded-state ${
                props.uploadType === "Address" ? "typ-address" : ""
              }`}
            >
              {props.uploadType === "Document" ? (
                <span className="upload-text">
                  {t("identityproof_documentUploaded")}
                </span>
              ) : props.uploadType === "Identity Proof" ? (
                <span className="upload-text">
                  {props.docSecDataObj?.identityProofType}
                </span>
              ) : props.uploadType === "Address" ? (
                <div className="label-value-wrap">
                  <div className="label-value">
                    <span className="label">{t("identityproof_pgTitle")}</span>
                    <p className="value">{props.docSecDataObj?.documentType}</p>
                  </div>
                  <div className="label-value">
                    <span className="label">{t("identityproof_address")}</span>
                    <p className="value">
                      {`${props.docSecDataObj?.houseNo}, ${props.docSecDataObj?.streetArea}, ${props.docSecDataObj?.region}, ${props.docSecDataObj?.province}, ${props.docSecDataObj?.zipcode}, ${props.docSecDataObj?.city}, ${props.docSecDataObj?.country}`}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <Button
                btnStyle="text"
                onClick={() => {
                  setOpenUploadDrawer(true);
                  setEditState(true);
                  setConfirmBtnState(false);
                }}
              >
                {t("identityproof_editBtn")}
              </Button>
            </div>
          ) : (
            <>
              <button
                type="button"
                className="upload-btn"
                onClick={() => {
                  setOpenUploadDrawer(true);
                  setEditState(false);
                  props.formikObj.setFieldTouched(`${props.fieldKey}`, true);
                }}
              >
                <span className="upload-text">{props.uploadText}</span>
                <i></i>
              </button>
            </>
          )}
        </div>
        {props.formikObj.touched[`${props.fieldKey}`] &&
        props.formikObj.errors[`${props.fieldKey}`] ? (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            {props.formikObj.touched[`${props.fieldKey}`] &&
              props.formikObj.errors[`${props.fieldKey}`]}
          </FormHelperText>
        ) : null}
      </div>
      <BottomSheet
        size="large"
        open={openUploadDrawer}
        setOpen={setOpenUploadDrawer}
        title={props.popupTitle}
        subText={props.popupDesc}
        primaryBtnText={t("identityproof_confirmBtn")}
        btnDisabled={confirmBtnState}
        btnOnClick={handleDocumentUploadConfirm}
      >
        {props.uploadType === "Document" ? (
          <>
            <div className={`${styles.uploadCont}`}>
              {/* <span className={`${styles.uploadTitle}`}>
              Upload {props.popupTitle}
            </span> */}
              {props.fieldKey === "financialStatements" ? (
                <>
                  <h4 className={`${styles.contTitle}`}>
                    {t("identityproof_fiscalYear")} {getCurrentFinancialYear()}
                  </h4>
                  <DocumentUpload
                    getFileData={handleFileUpload}
                    preUploadedFile={props.documentData}
                    preFileObj={props.docFileObj}
                    editState={editState}
                    index={0}
                    previewFileURL={previewFileURL}
                  ></DocumentUpload>
                  <h4 className={`${styles.contTitle}`}>
                    {t("identityproof_fiscalYear")} {getLastFinancialYear()}
                  </h4>
                  <DocumentUpload
                    getFileData={handleFileUpload}
                    preUploadedFile={props?.financialStatement1?.documentData}
                    preFileObj={props?.financialStatement1?.docFileObj}
                    editState={editState}
                    index={1}
                    previewFileURL={previewFileURL1}
                  ></DocumentUpload>
                </>
              ) : props.fieldKey !== "financialStatements" ? (
                <>
                  <DocumentUpload
                    getFileData={handleFileUpload}
                    preUploadedFile={props.documentData}
                    preFileObj={props.docFileObj}
                    editState={editState}
                    index={0}
                    previewFileURL={previewFileURL}
                  ></DocumentUpload>
                </>
              ) : null}
            </div>
          </>
        ) : props.uploadType === "Identity Proof" ? (
          <IdentityProof
            postDataToParent={handleDataFromChildIdentityProof}
            data={props?.docSecDataObj && props?.docSecDataObj?.documentFileName ? props?.docSecDataObj  : null}
          />
        ) : // <div className={`${styles.formWrap}`}>
        //   <div className="bs-form typ-drawer">
        //     <form>
        //       <div className="form-body">
        //         <div className="form-section">
        //           <h3 className="frm-sec-title">Select Document type</h3>
        //           <div className="form-group">
        //             <RadioGroupField
        //               name="document-type"
        //               defaultValue={docTypeRadio[0].value}
        //               radioField={docTypeRadio}
        //               radioChange={identityDocChange}
        //             ></RadioGroupField>
        //           </div>
        //         </div>
        //         {docType === "passport" ? (
        //           <>
        //             <div className="form-section">
        //               <h3 className="frm-sec-title">
        //                 Enter Passport Details
        //               </h3>
        //               <div className="form-group">
        //                 <FormControl variant="standard">
        //                   <TextField
        //                     id="passport"
        //                     label="Passport Number"
        //                     placeholder="Passport Number"
        //                     // value={formik.values.passport}
        //                     // onChange={formik.handleChange}
        //                     // onBlur={formik.handleBlur}
        //                     // error={
        //                     //   formik.touched.passport &&
        //                     //   Boolean(formik.errors.passport)
        //                     // }
        //                     // helperText={
        //                     //   formik.touched.passport &&
        //                     //   formik.errors.passport
        //                     // }
        //                   />
        //                 </FormControl>
        //               </div>
        //               <div className="form-group">
        //                 <div className="bs-datepicker">
        //                   <LocalizationProvider
        //                     dateAdapter={AdapterDayjs}
        //                     localeText={{
        //                       okButtonLabel: "Done",
        //                       cancelButtonLabel: "Close",
        //                     }}
        //                   >
        //                     <DatePicker
        //                       // className={
        //                       //   formik.errors.expiryDatePassport
        //                       //     ? "Mui-error"
        //                       //     : ""
        //                       // }
        //                       format="MM-DD-YYYY"
        //                       label="Expiry Date"
        //                       // value={formik.values.expiryDatePassport}
        //                       // onChange={(date) =>
        //                       //   formik.setFieldValue(
        //                       //     "expiryDatePassport",
        //                       //     date
        //                       //   )
        //                       // }
        //                       // onClose={() => {
        //                       //   formik.setFieldTouched(
        //                       //     "expiryDatePassport",
        //                       //     true
        //                       //   );
        //                       // }}
        //                     />
        //                     {/* {formik.touched.expiryDatePassport &&
        //                     formik.errors.expiryDatePassport ? (
        //                       <span className="MuiFormHelperText-root Mui-error">
        //                         {formik.errors.expiryDatePassport}
        //                       </span>
        //                     ) : null} */}
        //                   </LocalizationProvider>
        //                 </div>
        //               </div>
        //             </div>
        //             <div className="form-section">
        //               <h3 className="frm-sec-title">Upload Passport</h3>
        //               <div className="form-group">
        //                 <DocumentUpload></DocumentUpload>
        //               </div>
        //             </div>
        //           </>
        //         ) : (
        //           ""
        //         )}
        //         {docType === "identity card" ? (
        //           <>
        //             <div className="form-section">
        //               <h3 className="frm-sec-title">
        //                 Enter Identity Card Details
        //               </h3>
        //               <div className="form-group">
        //                 <FormControl variant="standard">
        //                   <TextField
        //                     id="idNum"
        //                     label="Identity Card Number"
        //                     placeholder="Identity Card Number"
        //                     // value={formik.values.idNum}
        //                     // onChange={formik.handleChange}
        //                     // onBlur={formik.handleBlur}
        //                     // error={
        //                     //   formik.touched.idNum &&
        //                     //   Boolean(formik.errors.idNum)
        //                     // }
        //                     // helperText={
        //                     //   formik.touched.idNum && formik.errors.idNum
        //                     // }
        //                   />
        //                 </FormControl>
        //               </div>
        //             </div>
        //             <div className="form-section">
        //               <h3 className="frm-sec-title">Upload Identity Card</h3>
        //               <div className="form-group">
        //                 <DocumentUpload></DocumentUpload>
        //               </div>
        //             </div>
        //           </>
        //         ) : (
        //           ""
        //         )}
        //       </div>
        //     </form>
        //   </div>
        // </div>
        props.uploadType === "Address" ? (
          <ResidenceProof
            postDataToParent={handleDataFromChildResidencyProof}
            data={props?.docSecDataObj && props?.docSecDataObj?.documentFileName ? props?.docSecDataObj  : null}
          />
        ) : (
          // <div className={`${styles.formWrap}`}>
          //   <div className="bs-form typ-drawer">
          //     <form>
          //       <div className="form-body">
          //         <div className="form-section">
          //           <h3 className="frm-sec-title">Add Address</h3>
          //           <div className="form-group">
          //             <div className="bs-select">
          //               <FormControl fullWidth>
          //                 <InputLabel className="input-label">
          //                   Country
          //                 </InputLabel>
          //                 <Select id="demo-simple-select" label="country">
          //                   <MenuItem value={"india"}>India</MenuItem>
          //                   <MenuItem value={"italy"}>Italy</MenuItem>
          //                   <MenuItem value={"usa"}>USA</MenuItem>
          //                 </Select>
          //               </FormControl>
          //             </div>
          //           </div>
          //           <div className="form-group">
          //             <FormControl variant="standard">
          //               <TextField
          //                 id="zip"
          //                 label="Zip Code"
          //                 placeholder="Zip Code"
          //               />
          //             </FormControl>
          //           </div>
          //           <div className="form-group">
          //             <FormControl variant="standard">
          //               <TextField
          //                 id="province"
          //                 placeholder="Province"
          //                 label="Province"
          //               />
          //             </FormControl>
          //           </div>
          //           <div className="form-group">
          //             <FormControl variant="standard">
          //               <TextField
          //                 id="region"
          //                 placeholder="Region"
          //                 label="Region"
          //               />
          //             </FormControl>
          //           </div>
          //           <div className="form-group">
          //             <div className="bs-select">
          //               <FormControl fullWidth>
          //                 <InputLabel className="input-label">City</InputLabel>
          //                 <Select id="demo-simple-select" label="city">
          //                   <MenuItem value={"mumbai"}>Mumbai</MenuItem>
          //                   <MenuItem value={"milan"}>Milan</MenuItem>
          //                   <MenuItem value={"washington"}>Washington</MenuItem>
          //                 </Select>
          //               </FormControl>
          //             </div>
          //           </div>
          //           <div className="form-group">
          //             <FormControl variant="standard">
          //               <TextField
          //                 id="street"
          //                 label="Street/Locality/Area"
          //                 placeholder="Street/Locality/Area"
          //               />
          //             </FormControl>
          //           </div>
          //           <div className="form-group">
          //             <FormControl variant="standard">
          //               <TextField
          //                 id="housenum"
          //                 placeholder="House Number"
          //                 label="House Number"
          //               />
          //             </FormControl>
          //           </div>
          //         </div>
          //         <div className="form-section">
          //           <h3 className="frm-sec-title">Upload Address Proof</h3>
          //           <div className="form-group">
          //             <div className="bs-select">
          //               <FormControl fullWidth>
          //                 <InputLabel className="input-label">
          //                   Choose Document
          //                 </InputLabel>
          //                 <Select
          //                   id="demo-simple-select"
          //                   // value={age}
          //                   label="Age"
          //                   // onChange={handleChange}
          //                 >
          //                   <MenuItem value={10}>Ten</MenuItem>
          //                   <MenuItem value={20}>Twenty</MenuItem>
          //                   <MenuItem value={30}>Thirty</MenuItem>
          //                 </Select>
          //               </FormControl>
          //             </div>
          //           </div>
          //           <div className="form-group">
          //             <div className="bs-datepicker">
          //               <LocalizationProvider
          //                 dateAdapter={AdapterDayjs}
          //                 localeText={{
          //                   okButtonLabel: "Done",
          //                   cancelButtonLabel: "Close",
          //                 }}
          //               >
          //                 <DatePicker
          //                   format="MM-DD-YYYY"
          //                   label="Issuance Date"
          //                 />
          //               </LocalizationProvider>
          //             </div>
          //           </div>
          //         </div>
          //         <div className="form-section">
          //           <h3 className="frm-sec-title">Upload Proof</h3>
          //           <div className="form-group">
          //             <DocumentUpload></DocumentUpload>
          //           </div>
          //         </div>
          //       </div>
          //     </form>
          //   </div>
          // </div>
          ""
        )}
      </BottomSheet>
    </>
  );
}

export default UploadCard;
