import React, { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import Header from "src/components/common/header/Header";
import styles from "./style_socket.module.scss";
import { FormControl, TextField, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import { getSessionKey } from "src/helper/sessionServices";

const ENDPOINT = "wss://asiaimpactapi.digitalsalt.in";

interface Message {
  message: string;
}

const ChatDetailsSocket: React.FC = () => {
  const { t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const location = useLocation();
  const historyData: any = location.state;

  const [value, setValue] = useState<number>(0);
  const [chatData, setChatData] = useState<any[]>([]);
  const [replyMsg, setReplyMsg] = useState<string>("");
  const [clientData, setClientData] = useState<any>({});
  const [sendBtnActive, setSendBtnActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<string[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messageStatus, setMessageStatus] = useState<
    "pending" | "sent" | "failed" | ""
  >("");
  const [notificationData, setNotificationData] = useState<any>({});
  // console.log('historyData', historyData.selectedUser);

  useEffect(() => {
    const userDataNotification =
      historyData?.reference === "notification"
        ? getSessionKey("notificationData")
        : "";
    let filteredData: any[] = [];
    if (Object.keys(userDataNotification).length > 0) {
      filteredData = userDataNotification.filter((item: any) => {
        return item.id === historyData?.notificationId;
      });
      setNotificationData(filteredData[0]);
    }

    const token = getSessionKey("authToken")?.accesstoken || "";
    const newSocket: Socket = io(ENDPOINT, {
      auth: {
        token: `Bearer ${token}`,
      },
      query: {
        email: getSessionKey("authToken").email.split("@")[0],
        id: getSessionKey("authToken").id,
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });
    setSocket(newSocket);

    newSocket.on("join", (messageData: any) => {
      //console.log('join:', messageData);
      // setMessages((prevMessages) => [...prevMessages, messageData.message]);
    });

    const receiver_id =
      historyData?.reference === "notification"
        ? filteredData[0]?.sender_id
        : getSessionKey("userRole") === "advisor"
        ? historyData.selectedUser?.investor?.id
        : historyData.selectedUser?.advisor?.id;
    const messageData1 = {
      id: getSessionKey("authToken").id,
      email: getSessionKey("authToken").email.split("@")[0],
      receiver_id: receiver_id,
    };

    //newSocket.emit("get_message", messageData1);
    /* newSocket.on('get_message', (messageData1: any) => {
      console.log('get message:', messageData1);
      // setMessages((prevMessages) => [...prevMessages, messageData.message]);
    }); */

    newSocket.on("message_history", (messageData: any) => {
      // console.log('message_history:', messageData);
      if (messageData.length > 1) {
        let tempChat = messageData.filter((item: any) => {
          const id =
            historyData?.reference === "notification"
              ? filteredData[0]?.sender_id
              : getSessionKey("userRole") === "advisor"
              ? historyData.selectedUser?.investor?.id
              : historyData.selectedUser?.advisor?.id;
          return item?.user_id === id;
        });
        if (tempChat.length > 0) {
          setChatData(tempChat[0]?.conversation?.messages);
        } else {
          setChatData([]);
        }
      } else {
        setChatData([]);
      }
    });

    // newSocket.on('receive_chat_message', (data: any) => {
    //   console.log('received message data', data);
    // })

    // console.log('get message:', messageData1);
    newSocket.emit("get_message", messageData1);
    const intervalState = setInterval(() => {
      newSocket.emit("get_message", messageData1);
    }, 2500);

    return () => {
      newSocket.disconnect();
      clearInterval(intervalState);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log('console for receive_chat_message from useffect', socket);
      socket.on("receive_chat_message", (data) => {
        console.log("received message data from useffect", data);
      });
    }
  }, [socket]);

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (socket && message.trim() !== "") {
      // console.log('historyData.selectedUser', historyData.selectedUser, notificationData);
      const receiver_id =
        historyData?.reference === "notification"
          ? notificationData?.sender_id
          : getSessionKey("userRole") === "advisor"
          ? historyData.selectedUser?.investor?.id
          : historyData.selectedUser?.advisor?.id;
      const messageData = {
        sender_id: getSessionKey("authToken").id,
        receiver_id: receiver_id,
        message: message,
        // email: getSessionKey('authToken').email.split('@')[0],
        email:
          historyData?.reference === "notification"
            ? notificationData?.sender_detail.email
            : getSessionKey("userRole") === "advisor"
            ? historyData.selectedUser?.investor?.email
            : historyData.selectedUser?.advisor?.email,
        conversation_id:
          chatData.length > 0 ? chatData[0]?.conversation_id : null,
      };
      console.log("messageData", messageData);
      socket.emit("send_chat_message", messageData, (ack: any) => {
        // console.log('send chat message', ack);
        if (ack.success) {
          setMessageStatus("sent");
        } else {
          setMessageStatus("failed");
        }
      });
      // socket.on('receive_chat_message', (data) => {
      //   console.log('received message data from useffect', data);
      // })
      setMessage("");
      setSendBtnActive(false);
      setMessageStatus("pending");
    }
  };

  // const receiveMessage = () => {
  //   if (socket) {
  //     socket.on('receiveMessage', (data) => {
  //       console.log('received message data', data);
  //     })
  //   }
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = () => {
    // Add any logic you need here
  };

  const formatTime = (dateString: string): string => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getChatHistory = () => {
    // Add logic to get chat history here
  };

  const handleReply = () => {
    // Add logic to handle replies here
  };

  return (
    <>
      <header>
        <Header
          backLink={true}
          actionItems={[]}
          pageTitle={
            historyData?.reference === "notification"
              ? notificationData?.sender_detail?.role === "legalrepresent"
                ? notificationData?.sender_detail?.lrDetails[0]?.companyname
                : `${
                    notificationData?.sender_detail?.userdetail?.firstname
                  }${" "}${
                    notificationData?.sender_detail?.userdetail?.lastname
                  }`
              : getSessionKey("userRole") === "advisor"
              ? historyData.selectedUser?.investor?.role === "legalrepresent"
                ? `${historyData.selectedUser?.investor?.userLrdetail[0]?.companyname}`
                : `${
                    historyData.selectedUser?.investor?.userdetail?.firstname
                  }${" "}${
                    historyData.selectedUser?.investor?.userdetail?.lastname
                  }`
              : `${
                  historyData.selectedUser?.advisor?.userdetail.firstname
                }${" "}${
                  historyData.selectedUser?.advisor?.userdetail.lastname
                }`
          }
          center={true}
          clientData={clientData}
        />
      </header>
      {/* <main>
        <div className="lyt-main typ-message">
          <form onSubmit={sendMessage}>
            <input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button type="submit">Send</button>
          </form>
          <ul>
            {messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
          {messageStatus === 'pending' && <p>Sending message...</p>}
          {messageStatus === 'sent' && <p>Message sent successfully!</p>}
          {messageStatus === 'failed' && <p>Failed to send message. Please try again.</p>}
        </div>
      </main> */}
      <main>
        <div className="lyt-main typ-message">
          <div
            className={`${styles.messageGroup} ${
              value === 1 ? `${styles.hidden}` : ""
            }`}
          >
            {/* {chatData.length <= 1 && (
              <div className={`${styles.infoCont}`}>
                <i className={`${styles.error}`}></i>
                <span className={`${styles.desc}`}>
                  {t("chat_detailsInfo")}
                </span>
              </div>
            )} */}
            <ul className={`${styles.list}`}>
              {chatData.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={`${styles.item} ${
                      item?.sender_id !== getSessionKey("authToken").id
                        ? styles.receive
                        : styles.send
                    }`}
                  >
                    <div className={`${styles.wrap}`}>
                      <p className={`${styles.text}`}>{item?.message}</p>
                    </div>
                    <span className={`${styles.time}`}>
                      {formatTime(item?.createdAt)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={`${styles.footWrap}`}>
            <div className={`${styles.tabWrap}`}>
              <div className="bs-tabs typ-list typ-chat">
                {/* <Tabs value={value} onChange={handleChange}>
                  <Tab label={t("chat_chatBtn")} />
                  <Tab label={t("chat_detailsCallOfficer")} />
                </Tabs> */}
                <div className={`${styles.panelWrap}`}>
                  <div
                    className={`${styles.tabPanel}`}
                    role="tabpanel"
                    hidden={value !== 0}
                  ></div>
                  <div
                    className={`${styles.tabPanel}`}
                    role="tabpanel"
                    hidden={value !== 1}
                  >
                    <div className={`${styles.tabContent}`}>
                      <div className={`${styles.headWrap}`}>
                        <h3 className={`${styles.title}`}>
                          {t("chat_detailsInviteTitle")}
                        </h3>
                        <p className={`${styles.subTitle}`}>
                          {t("chat_detailsInviteSubTitle")}
                        </p>
                      </div>
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("chat_detailsInviteProcess")}
                        </h2>
                        <div className={`${styles.cardWrap}`}>
                          <div className={`${styles.card}`}>
                            <div className={`${styles.stepsList}`}>
                              <div
                                className={`${styles.steps} ${styles.active}`}
                              >
                                <p>{t("chat_detailsCallOfficer")}</p>
                              </div>
                              <div
                                className={`${styles.steps} ${
                                  chatData[0]?.activerequestchat
                                    ?.ai_officer_status === "pending" ||
                                  chatData[0]?.activerequestchat
                                    ?.ai_officer_status === "confirm"
                                    ? styles.active
                                    : ""
                                }`}
                              >
                                <p>{t("chat_detailsWaitingStep")}</p>
                              </div>
                              <div
                                className={`${styles.steps} ${
                                  chatData[0]?.activerequestchat
                                    ?.ai_officer_status === "confirm"
                                    ? styles.active
                                    : ""
                                }`}
                              >
                                <p>{t("chat_detailsApprovalStep")}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {chatData[0]?.activerequestchat?.ai_officer_status !==
                      "confirm" &&
                    chatData[0]?.activerequestchat?.ai_officer_status !==
                      "pending" ? (
                      <div className={`${styles.footBtn}`}>
                        <Button btnStyle="primary" onClick={handleClick}>
                          {t("chat_detailsCallOfficer")}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.inputGroup} ${
                value === 1 ? `${styles.hidden}` : ""
              }`}
            >
              <div className="bs-form typ-full">
                <div className="message-wrap">
                  <FormControl variant="standard">
                    <TextField
                      id="message"
                      multiline
                      maxRows={4}
                      placeholder={t("chat_detailsPlaceholder")}
                      name="message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        if (e.target.value.length !== 0) {
                          setSendBtnActive(true);
                        } else {
                          setSendBtnActive(false);
                        }
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <button
                type="button"
                className={`${sendBtnActive ? styles.btnActive : ""}`}
                onClick={sendMessage}
              >
                <i className={`${styles.send}`}></i>
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ChatDetailsSocket;
