import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";

function AdvisorDetails() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const navigate = useNavigate();
  const [relationData, setRelationData] = useState<any>([]);
  const handleClick = () => {
    navigate("/add-advisor");
  };

  useEffect(() => {
    axios
      .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation", {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        setRelationData(response?.data?.data);
        setSessionKey("relationData", response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className="bs-section typ-lg-top">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("advisor_detailsTitle")}
                </h1>
                <p className={`${styles.subTitle}`}>
                  {t("advisor_clientSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.subWrap}`}>
                  <ul className={`${styles.subList}`}>
                    {relationData.length > 0 &&
                      relationData.map((item: any, index: number) => {
                        return item?.requestStatus === "approved" ? (
                          <li className={`${styles.subItem}`}>
                            <Link to={`/advisor-details?id=${item?.id}`}>
                              <div className={`${styles.logoWrap}`}>
                                <img
                                  src={
                                    item?.advisor?.userdetail.img === "" ||
                                    item?.advisor?.userdetail.img === null
                                      ? "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"
                                      : item?.advisor?.userdetail.img
                                  }
                                  alt={item?.advisor?.userdetail.firstname}
                                />
                              </div>
                              <div className={`${styles.titleWrap}`}>
                                <strong className={`${styles.clientName}`}>
                                  {item?.advisor?.userdetail.firstname}{" "}
                                  {item?.advisor?.userdetail.lastname}
                                </strong>
                              </div>
                              <span className={`${styles.arrowIcon}`}></span>
                            </Link>
                          </li>
                        ) : null;
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={`${styles.footWrap}`}>
          <Button btnStyle="primary" onClick={handleClick}>
            {t("advisor_introTitle")}
          </Button>
        </div>
      </main>
    </>
  );
}

export default AdvisorDetails;
