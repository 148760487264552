import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { FormControl, TextField, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import axios from "axios";

function ChatDetails() {
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [chatData, setChatData] = useState<any[]>([]);
  const [replyMsg, setReplyMsg] = useState<any>("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [clientData, setClientData] = useState<any>({});
  const [sendBtnActive, setSendBtnActive] = useState(false);

  const handleClick = () => {
    // let historyData = {
    //   notificationId: clientData?.id,
    //   activeChatReqId: location.state.reference === "notification" ? clientData?.activechatrequestid : clientData?.activerequestchathistory[0]?.activechatrequestid,
    //   reference: location.state.reference
    // };
    // navigate("/chat-confirm-request", { state: historyData });
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    const payload = {
      id: clientData?.id,
      companyid: clientData?.companyid,
      activerequestid: clientData?.activerequestid,
      activechatrequestid: location.state.activeChatReqId,
      activechatrequest: {
        ai_officer_request_raised_by:
          getSessionKey("userRole") === "advisor"
            ? getSessionKey("selectedClient")?.investor?.id
            : getSessionKey("authToken").id,
        ai_officer_status: "pending", // pending,accepted,rejected
        notification: {
          // to be passed only when user called ai officier
          companyid: clientData?.companyid,
          activerequestid: clientData?.activerequestid,
          activechatrequestid: location.state.activeChatReqId,
          sender_id:
            getSessionKey("userRole") === "advisor"
              ? getSessionKey("selectedClient")?.investor?.id
              : getSessionKey("authToken").id,
          receiver_id:
            chatData[chatData.length - 1].receiver_id ===
            (getSessionKey("userRole") === "advisor"
              ? getSessionKey("selectedClient")?.investor?.id
              : getSessionKey("authToken").id)
              ? chatData[chatData.length - 1].sender_id
              : chatData[chatData.length - 1].receiver_id,
          type: "approval pending",
          message: `${
            chatData[0]?.sender_id ===
            (getSessionKey("userRole") === "advisor"
              ? getSessionKey("selectedClient")?.investor?.id
              : getSessionKey("userData").id)
              ? chatData[0]?.Sender?.role === "legalrepresent"
                ? chatData[0]?.Sender?.lrDetails[0]?.companyname
                : `${chatData[0]?.Sender?.userdetail?.firstname} ${chatData[0]?.Sender?.userdetail?.lastname}`
              : chatData[0]?.Receiver?.role === "legalrepresent"
              ? chatData[0]?.Receiver?.lrDetails[0]?.companyname
              : `${chatData[0]?.Receiver?.userdetail?.firstname} ${chatData[0]?.Receiver?.userdetail?.lastname}`
          } has requested your approval to invite the AI Officer.`,
        },
      },
    };

    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "AI officer invited",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleAcceptButtonClick = () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };

    const payload = {
      id: clientData?.id,
      companyid: clientData?.companyid,
      activerequestid: clientData?.activerequestid,
      activechatrequestid: location.state.activeChatReqId,
      activechatrequest: {
        // "ai_officer_request_raised_by": getSessionKey("authToken").id,
        ai_officer_status: "confirm", // pending,accepted,rejected
        notification: {
          // to be passed only when user called ai officier
          companyid: clientData?.companyid,
          activerequestid: clientData?.activerequestid,
          activechatrequestid: location.state.activeChatReqId,
          sender_id:
            getSessionKey("userRole") === "advisor"
              ? getSessionKey("selectedClient")?.investor?.id
              : getSessionKey("authToken").id,
          receiver_id:
            chatData[chatData.length - 1].receiver_id ===
            (getSessionKey("userRole") === "advisor"
              ? getSessionKey("selectedClient")?.investor?.id
              : getSessionKey("authToken").id)
              ? chatData[chatData.length - 1].sender_id
              : chatData[chatData.length - 1].receiver_id,
          type: "message",
          title: "AI-X Request Accepted",
          message: `${
            getSessionKey("userRole") === "legalrepresent"
              ? getSessionKey("userData")?.userdetail?.userlrdetail[0]
                  ?.companyname
              : getSessionKey("userData").userdetail?.firstname +
                " " +
                getSessionKey("userData").userdetail?.lastname
          } had confirm your invitation request to invite the AI Officer.`,
        },
      },
    };

    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "AI officer Request Confirm",
          };
          axios
            .get(
              "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
              {
                headers: {
                  Authorization: `Bearer ${
                    getSessionKey("authToken") !== null
                      ? getSessionKey("authToken").accesstoken
                      : ""
                  }`,
                },
              }
            )
            .then((response) => {
              if (response?.data?.success) {
                // setNotificationData(response?.data?.data);
                setSessionKey("notificationData", response?.data?.data);
                if (
                  getSessionKey("notificationData").some(
                    (obj: any) =>
                      location.state.activeChatReqId === obj.activechatrequestid
                  )
                ) {
                  let tempNotiItem = getSessionKey("notificationData").filter(
                    (obj: any) => {
                      return (
                        location.state.activeChatReqId ===
                          obj.activechatrequestid &&
                        obj.message.indexOf(
                          "has requested your approval to invite the AI Officer."
                        ) !== -1
                      );
                    }
                  );

                  let notificationPayload: any = {
                    id: tempNotiItem[0].id,
                    is_read: true,
                    tableType: "ACTIVE_CHAT_REQUEST_HISTORY",
                  };
                  axios
                    .put(
                      "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/notification",
                      notificationPayload,
                      {
                        headers: {
                          Authorization: `Bearer ${
                            getSessionKey("authToken") !== null
                              ? getSessionKey("authToken").accesstoken
                              : ""
                          }`,
                        },
                      }
                    )
                    .then((response) => {
                      if (response?.data?.success) {
                        navigate("/acknowledgement", { state: historyData });
                      }
                    })
                    .catch((error) => {
                      if (
                        error.response.status === 403 ||
                        error.response.status === 401
                      ) {
                        navigate("/enter-pin");
                      } else {
                        console.error("Error fetching data:", error.message);
                        // let historyData = {
                        //   notificationId: notificationId,
                        //   reference: "notification",
                        // };
                        // navigate(
                        //   `${"/chat-details-socket"}`,
                        //   { state: historyData }
                        // );
                      }
                    });
                }
              }
            })
            .catch((error) => {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                navigate("/enter-pin");
              } else {
                console.error("Error fetching data:", error.message);
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    // navigate("/chat-details");
  };

  useEffect(() => {
    i18next.changeLanguage(lang);

    getChatHistory();
    const intervalState = setInterval(() => {
      getChatHistory();
    }, 10000);

    const userDataNotification =
      location.state.reference === "notification"
        ? getSessionKey("notificationData")
        : getSessionKey("activeChatRequestData");
    const filteredData = userDataNotification.filter((item: any) => {
      return (
        item.id ===
        (location.state.reference === "chatPage"
          ? location.state.activeChatReqId
          : location.state.notificationId)
      );
    });
    console.log(getSessionKey("notificationData"), "Userdata notification");

    console.log(
      "filteredData[0]",
      filteredData[0],
      location.state.reference,
      location.state.notificationId,
      location.state.activeChatReqId,
      userDataNotification
    );
    setClientData(
      location.state.reference === "chatPage"
        ? filteredData[0]?.activerequestchathistory[0]
        : filteredData[0]
    );

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalState);
  }, []);

  const formatTime = (dateString: string): string => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getChatHistory = () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory?activechatrequestid=${location.state.activeChatReqId}`,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          console.log("res.data.data.reverse()", res.data.data);
          setChatData(res.data.data.reverse());
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleReply = () => {
    if (replyMsg !== "") {
      const headers = {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
          "Content-Type": "application/json",
        },
      };
      const payload = {
        companyid: chatData[0].companyid,
        activerequestid: chatData[0].activerequestid,
        activechatrequestid: location.state.activeChatReqId,
        sender_id:
          getSessionKey("userRole") === "advisor"
            ? getSessionKey("selectedClient")?.investor?.id
            : getSessionKey("authToken").id,
        receiver_id:
          chatData[chatData.length - 1].receiver_id ===
          (getSessionKey("userRole") === "advisor"
            ? getSessionKey("selectedClient")?.investor?.id
            : getSessionKey("authToken").id)
            ? chatData[chatData.length - 1].sender_id
            : chatData[chatData.length - 1].receiver_id,
        message: replyMsg,
      };
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
          payload,
          headers
        )
        .then((res) => {
          if (res.data.success) {
            setReplyMsg("");
            getChatHistory();
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setSendBtnActive(false);
    }
  };

  return (
    <>
      <header>
        <Header
          backLink={true}
          actionItems={["more"]}
          pageTitle={
            chatData.length <= 1
              ? t("chat_detailsAnonymous")
              : chatData[0]?.sender_id !==
                (getSessionKey("userRole") === "advisor"
                  ? getSessionKey("selectedClient")?.investor?.id
                  : getSessionKey("userData").id)
              ? chatData[0]?.Sender?.role === "legalrepresent"
                ? chatData[0]?.Sender?.lrDetails[0]?.companyname
                : `${chatData[0]?.Sender?.userdetail?.firstname} ${chatData[0]?.Sender?.userdetail?.lastname}`
              : chatData[0]?.Receiver?.role === "legalrepresent"
              ? chatData[0]?.Receiver?.lrDetails[0]?.companyname
              : `${chatData[0]?.Receiver?.userdetail?.firstname} ${chatData[0]?.Receiver?.userdetail?.lastname}`
          }
          center={true}
          clientData={clientData}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-message">
          <div
            className={`${styles.messageGroup} ${
              value === 1 ? `${styles.hidden}` : ""
            }`}
          >
            {chatData.length <= 1 && (
              <div className={`${styles.infoCont}`}>
                <i className={`${styles.error}`}></i>
                <span className={`${styles.desc}`}>
                  {t("chat_detailsInfo")}
                </span>
              </div>
            )}
            <ul className={`${styles.list}`}>
              {chatData.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={`${styles.item} ${
                      item?.receiver_id ===
                      (getSessionKey("userRole") === "advisor"
                        ? getSessionKey("selectedClient")?.investor?.id
                        : getSessionKey("authToken").id)
                        ? styles.receive
                        : styles.send
                    }`}
                  >
                    <div className={`${styles.wrap}`}>
                      <p className={`${styles.text}`}>{item?.message}</p>
                    </div>
                    <span className={`${styles.time}`}>
                      {formatTime(item?.createdAt)}
                    </span>
                  </li>
                );
              })}
              {/* <li className={`${styles.item} ${styles.receive}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    I am interested in buying 500 DRs for 5€/DR. I am highly
                    interested in the company and want to contribute to its
                    growth.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li>
              <li className={`${styles.item} ${styles.send}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    Hey, I am interested in the offer. However, I want to revise
                    the price a bit.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li>
              <li className={`${styles.item} ${styles.send}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    Hey, I am interested in the offer. However, I want to revise
                    the price a bit.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li>
              <li className={`${styles.item} ${styles.send}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    Hey, I am interested in the offer. However, I want to revise
                    the price a bit.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li>
              <li className={`${styles.item} ${styles.receive}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    Hey, I am interested in the offer. However, I want to revise
                    the price a bit.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li>
              <li className={`${styles.item} ${styles.receive}`}>
                <div className={`${styles.wrap}`}>
                  <p className={`${styles.text}`}>
                    Hey, I am interested in the offer. However, I want to revise
                    the price a bit.
                  </p>
                </div>
                <span className={`${styles.time}`}>23:56</span>
              </li> */}
            </ul>
          </div>
          <div className={`${styles.footWrap}`}>
            {(getSessionKey("userRole") === "advisor" &&
              getSessionKey("selectedClient") !== null &&
              getSessionKey("selectedClient")?.participateinChat) ||
            getSessionKey("userRole") !== "advisor" ? (
              <>
                <div className={`${styles.tabWrap}`}>
                  <div className="bs-tabs typ-list typ-chat">
                    <Tabs value={value} onChange={handleChange}>
                      <Tab label={t("chat_chatBtn")} />
                      <Tab label={t("chat_detailsCallOfficer")} />
                    </Tabs>
                    <div className={`${styles.panelWrap}`}>
                      <div
                        className={`${styles.tabPanel}`}
                        role="tabpanel"
                        hidden={value !== 0}
                      ></div>
                      <div
                        className={`${styles.tabPanel}`}
                        role="tabpanel"
                        hidden={value !== 1}
                      >
                        <div className={`${styles.tabContent}`}>
                          <div className={`${styles.headWrap}`}>
                            <h3 className={`${styles.title}`}>
                              {t("chat_detailsInviteTitle")}
                            </h3>
                            <p className={`${styles.subTitle}`}>
                              {t("chat_detailsInviteSubTitle")}
                            </p>
                          </div>
                          <div className={`${styles.section}`}>
                            <h2 className={`${styles.secTitle}`}>
                              {t("chat_detailsInviteProcess")}
                            </h2>
                            <div className={`${styles.cardWrap}`}>
                              <div className={`${styles.card}`}>
                                <div className={`${styles.stepsList}`}>
                                  <div
                                    className={`${styles.steps} ${styles.active}`}
                                  >
                                    <p>{t("chat_detailsCallOfficer")}</p>
                                  </div>
                                  <div
                                    className={`${styles.steps} ${
                                      chatData[0]?.activerequestchat
                                        ?.ai_officer_status === "pending" ||
                                      chatData[0]?.activerequestchat
                                        ?.ai_officer_status === "confirm"
                                        ? styles.active
                                        : ""
                                    }`}
                                  >
                                    <p>{t("chat_detailsWaitingStep")}</p>
                                  </div>
                                  <div
                                    className={`${styles.steps} ${
                                      chatData[0]?.activerequestchat
                                        ?.ai_officer_status === "confirm"
                                        ? styles.active
                                        : ""
                                    }`}
                                  >
                                    <p>{t("chat_detailsApprovalStep")}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(chatData[0]?.activerequestchat?.ai_officer_status !==
                          "confirm" &&
                          chatData[0]?.activerequestchat?.ai_officer_status ===
                            "pending" &&
                          chatData[0]?.activerequestchat
                            ?.ai_officer_request_raised_by !==
                            (getSessionKey("userRole") === "advisor"
                              ? getSessionKey("selectedClient")?.investor?.id
                              : getSessionKey("authToken").id)) ||
                        chatData[0]?.activerequestchat?.ai_officer_status ===
                          null ? (
                          <div className={`${styles.footBtn}`}>
                            <Button
                              btnStyle="primary"
                              onClick={
                                chatData[0]?.activerequestchat
                                  ?.ai_officer_status === "pending" &&
                                chatData[0]?.activerequestchat
                                  ?.ai_officer_request_raised_by !==
                                  (getSessionKey("userRole") === "advisor"
                                    ? getSessionKey("selectedClient")?.investor
                                        ?.id
                                    : getSessionKey("authToken").id)
                                  ? handleAcceptButtonClick
                                  : handleClick
                              }
                            >
                              {chatData[0]?.activerequestchat
                                ?.ai_officer_status === "pending" &&
                              chatData[0]?.activerequestchat
                                ?.ai_officer_request_raised_by !==
                                (getSessionKey("userRole") === "advisor"
                                  ? getSessionKey("selectedClient")?.investor
                                      ?.id
                                  : getSessionKey("authToken").id)
                                ? "Confirm AI-X Officer Request"
                                : t("chat_detailsCallOfficer")}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.inputGroup} ${
                    value === 1 ? `${styles.hidden}` : ""
                  }`}
                >
                  <div className="bs-form typ-full">
                    <div className="message-wrap">
                      <FormControl variant="standard">
                        <TextField
                          id="message"
                          multiline
                          maxRows={4}
                          placeholder={t("chat_detailsPlaceholder")}
                          name="message"
                          value={replyMsg}
                          onChange={(e) => {
                            if (e.target.value.length !== 0) {
                              setSendBtnActive(true);
                            } else {
                              setSendBtnActive(false);
                            }
                            setReplyMsg(e.target.value);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`${sendBtnActive ? styles.btnActive : ""}`}
                    onClick={handleReply}
                  >
                    <i className={`${styles.send}`}></i>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
}

export default ChatDetails;
