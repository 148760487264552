import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { formatDate } from "src/helper/formatDate";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";

function RequestCard(props: any) {
  const [isNew, setIsNew] = useState<boolean>(false);
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const [currUser, setCurrUser] = useState("");
  const navigate = useNavigate();
  const [companyAcReq, setCompanyAcReq] = useState([]);
  const chatBtnStatus = (item: any) => {
    return companyAcReq.some((obj: any) => obj.activerequestid === item.id);
  };

  const chatRedirection = (item: any) => {
    const temp: any[] = companyAcReq.filter(
      (obj: any) => obj.activerequestid === item.id
    );
    if (temp.some((obj: any) => obj.sender_id === Number(currUser))) {
      navigate("/chat", {
        state: {
          activeTab: 0,
          reference: "companyCard",
        },
      });
    } else {
      if (temp[0]?.activerequestchathistory.length === 1) {
        navigate("/chat", {
          state: {
            activeTab: 2,
            reference: "companyCard",
          },
        });
      } else {
        navigate("/chat", {
          state: {
            activeTab: 1,
            reference: "companyCard",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (getSessionKey("userRole") === "advisor") {
      setCurrUser(getSessionKey("selectedClient")?.investor?.id);
    } else {
      setCurrUser(getSessionKey("userData")?.id);
    }
    const todayDate = new Date();
    const temp = new Date(
      props.parentRef === "companyChatCard"
        ? formatDate(props?.data?.createdAt)
        : props?.lastUpdateDate
    );
    if (
      todayDate.getFullYear() === temp.getFullYear() &&
      todayDate.getMonth() === temp.getMonth() &&
      todayDate.getDate() === temp.getDate()
    ) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequest?companyid=${props?.companyid}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log("REs-->", res.data, props);
          setCompanyAcReq(res.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    console.log(props, "PROPS");
  }, [props]);

  const handleChatRequest = (chatHistoryId: any) => {
    console.log("props.parentRef", props.parentRef, chatHistoryId);
    if (props.parentRef === "companyChatCard") {
      let historyData = {
        activeChatReqId: chatHistoryId,
        notificationId: chatHistoryId,
        reference: "chatPage",
      };
      navigate(`/chat-details`, { state: historyData });
    } else {
      let historyData = {
        notificationId: chatHistoryId,
        reference: "chatPage",
      };
      navigate(`/chat-message-page`, { state: historyData });
    }
  };

  // console.log("selectedCard", props?.status, props?.cardType, props);
  return (
    <div className={`${styles.cardWrap} `}>
      <div className={`${styles.headWrap}`}>
        <span className={`${styles.cardTitle}`}>
          <i className={`${styles.icon} ${styles.company}`}></i>
          {`${
            props.parentRef === "companyChatCard" &&
            props?.cardType === "outgoing"
              ? props?.data?.activerequestchathistory[
                  props?.data?.activerequestchathistory.length - 1
                ]?.Sender_Detail?.lrDetails.length > 0
                ? currUser ===
                  props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.lrDetails[0]?.id
                  ? "You"
                  : props?.data?.activerequestchathistory[
                      props?.data?.activerequestchathistory.length - 1
                    ]?.Sender_Detail?.lrDetails[0]?.companyname
                : currUser ===
                  props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.userdetail?.id
                ? "You"
                : props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.userdetail?.firstname
              : props.parentRef === "companyDetails"
              ? `${t("companydetails_requestTitle")} #${props.index}`
              : props.parentRef === "companyChatCard"
              ? props?.data?.activerequestchathistory[
                  props?.data?.activerequestchathistory.length - 1
                ]?.Sender_Detail?.lrDetails.length > 0
                ? currUser ===
                  props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.lrDetails[0]?.id
                  ? "You"
                  : props?.data?.activerequestchathistory[
                      props?.data?.activerequestchathistory.length - 1
                    ]?.Sender_Detail?.lrDetails[0]?.companyname
                : currUser ===
                  props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.userdetail?.id
                ? "You"
                : props?.data?.activerequestchathistory[
                    props?.data?.activerequestchathistory.length - 1
                  ]?.Sender_Detail?.userdetail?.firstname
              : props?.Company?.name
          }`}
        </span>
        {isNew ? (
          <span className={`${styles.cardTag}`}>
            {t("companydetails_newTagTitle")}
          </span>
        ) : (
          <span className={`${styles.date}`}>
            {props.parentRef === "companyChatCard"
              ? formatDate(props?.data?.createdAt)
              : formatDate(props?.lastUpdateDate)}
          </span>
        )}
      </div>
      <div className={`${styles.footWrap}`}>
        <p className={`${styles.cardDesc}`}>
          {`${
            props.parentRef === "companyDetails"
              ? `Hey, I am interested in ${props.companyName}. Do you want to chat with me?`
              : props.parentRef === "companyChatCard"
              ? props?.data?.activerequestchathistory[
                  props?.data?.activerequestchathistory.length - 1
                ].message
              : props?.message
          }`}
        </p>
        {props.parentRef === "companyDetails" ? (
          <>
            <button
              className="cm-chat-btn"
              onClick={() => {
                chatRedirection(props);
              }}
              disabled={!chatBtnStatus(props)}
            >
              Chat
            </button>
          </>
        ) : props.parentRef === "companyChatCard" &&
          (getSessionKey("userRole") === "individual_investor" ||
            getSessionKey("userRole") === "legalrepresent" ||
            getSessionKey("userRole") === "advisor") ? (
          // (getSessionKey("userRole") === "advisor" && getSessionKey("selectedClient")?.participateinChat)) ? (
          <>
            <button
              className="cm-chat-btn"
              onClick={() => {
                handleChatRequest(
                  props?.data?.activerequestchathistory[0]?.activechatrequestid
                );
              }}
            >
              Chat
            </button>
          </>
        ) : getSessionKey("userRole") === "individual_investor" ||
          getSessionKey("userRole") === "legalrepresent" ||
          getSessionKey("userRole") === "advisor" ? (
          // (getSessionKey("userRole") === "advisor" && getSessionKey("selectedClient")?.participateinChat) ? (
          <>
            <button
              className="cm-chat-btn"
              onClick={() => {
                handleChatRequest(props?.id);
              }}
            >
              Chat
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default RequestCard;
